import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faXmark } from '@fortawesome/pro-regular-svg-icons';
import 'tippy.js/dist/tippy.css';
import { glossaryConfig } from '../config/glossaryConfig';

import cbcWithDiffImage from '../images/pg 37-38_Anemia LV14 Part 2-05.png';
import cbcWithoutReflexImage from '../images/Pg 67-68_Anemia LV14 Part 3-07.png';
import cbcWithReflexImage from '../images/Pg 80-81-82_Anemia LV14 Part 3-20.png';
import closeButton from '../images/PatientCase_PopUp BUTTON.png';

const ONE_MINUTE = 60.0;
const TWO_MINUTES = 120.0;
const THREE_MINUTES = 180.0;
const FOUR_MINUTES = 240.0;
const FIVE_MINUTES = 300.0;
const SIX_MINUTES = 360.0;
const SEVEN_MINUTES = 420.0;
const EIGHT_MINUTES = 480.0;
const NINE_MINUTES = 540.0;
const TEN_MINUTES = 600.0;
const ELEVEN_MINUTES = 660.0;
const TWELVE_MINUTES = 720.0;
const THIRTEEN_MINUTES = 780.0;

const VideoHotSpots = ({
  currentTime,
  currentIndex,
  videoEl,
  videoIndexs,
  setOverlayOpen,
  overlayOpen
}) => {
  const navigate = useNavigate();
  //INTRO HOTSPOTS
  const [LungCancerIntro, setLungCancerIntro] = useState(false);
  const [breastCancerIntro, setBreastCancerIntro] = useState(false);
  const [anemiaIntro, setAnemiaIntro] = useState(false);

  //BREAST HOT SPOTS
  const [isHotSpot2Active, setIsHotSpot2Active] = useState(false);
  const [isHotSpot3Active, setIsHotSpot3Active] = useState(false);
  const [iscTNMHotSpotActive, setcTNMHotSpotActive] = useState(false);
  const [ispTNMHotSpotActive, setpTNMHotSpotActive] = useState(false);
  const [isClosePopupActivecTNM, setClosePopupActivecTNM] = useState(false);
  const [isClosePopupActivepTNM, setClosePopupActivepTNM] = useState(false);
  const [isAssessPrimaryHotSpotActive, setAssessPrimaryHotSpotActive] = useState(false);
  const [isMeasureTumorHotSpotActive, setMeasureTumorHotSpotActive] = useState(false);
  const [isMeasureTumorTwoHotSpotActive, setMeasureTumorTwoHotSpotActive] = useState(false);
  const [isReviewTCategoriesHotSpotActive, setReviewTCategoriesHotSpotActive] = useState(false);
  const [isNextNodalAssessHotSpotActive, setNextNodalAssessHotSpotActive] = useState(false);
  const [isClosePopupActiveTumoranatomic, setClosePopupActiveTumoranatomic] = useState(false);
  const [isNAssesRegionalHotSpotActive, setNAssesRegionalHotSpotActive] = useState(false);
  const [isReviewAllCNHotSpotActive, setReviewAllCNHotSpotActive] = useState(false);
  const [isFinallyAssesSpotActive, setFinallyAssesSpotActive] = useState(false);
  const [isClinicalAnatomicPopUpActive, setClinicalAnatomicPopUpActive] = useState(false);
  const [isImagingHotSpotActive, setImagingHotSpotActive] = useState(false);
  const [isBodyScanStartHotSpotActive, setBodyScanStartHotSpotActive] = useState(false);
  const [isRadiographicPopUpActive, setRadiographicPopUpActive] = useState(false);
  const [isReviewAllMHotSpotActive, setReviewAllMHotSpotActive] = useState(false);
  const [isCalculateStageHotSpotActive, setCalculateStageHotSpotActive] = useState(false);
  const [isDistantMetastasesPopUpActive, setDistantMetastasesPopUpActive] = useState(false);
  const [isDetermineHer2HotSpotActive, setIsDetermineHer2HotSpotActive] = useState(false);
  const [isTumorBiopsyHotSpotActive, setTumorBiopsyHotSpotActive] = useState(false);
  const [isLiquidBiopsyHotSpotActive, setLiquidBiopsyHotSpotActive] = useState(false);
  const [isTwentyTwoGuageHotSpotActive, setTwentyTwoGuageHotSpotActive] = useState(false);
  const [isFourteenGuageHotSpotActive, setFourteenGuageHotSpotActive] = useState(false);
  const [isScalpelHotSpotActive, setScalpelHotSpotActive] = useState(false);
  const [isTumorBiopsyNeedleHotSpotActive, setTumorBiopsyNeedleHotSpotActive] = useState(false);
  const [isFourteenGuageTumorHotSpotActive, setFourteenGuageTumorHotSpotActive] = useState(false);
  const [isScalpelTumorHotSpotActive, setScalpelTumorHotSpotActive] = useState(false);
  const [isLiquidBiopsyNGSHotSpotActive, setLiquidBiopsyNGSHotSpotActive] = useState(false);
  const [isLiquidBiopsySampleHotSpotActive, setLiquidBiopsySampleHotSpotActive] = useState(false);
  const [isIHCTestingHotSpotActive, setIHCTestingHotSpotActive] = useState(false);
  const [isISHTestingHotSpotActive, setISHTestingHotSpotActive] = useState(false);
  const [isNGSTestingHotSpotActive, setNGSTestingHotSpotActive] = useState(false);
  //LUNG CANCER HOTSPOTS
  const [exploreComponents, setExploreComponents] = useState(false);
  const [assesStage, setAssesStage] = useState(false);
  const [testBiomarkers, setTestBiomarkers] = useState(false);
  const [componentsImaging, setComponentsImaging] = useState(false);
  const [componentsBiopsy, setComponentsBiopsy] = useState(false);
  const [componentsHistological, setComponentsHistological] = useState(false);
  const [componentsBackToLung, setComponentsBackToLung] = useState(false);
  const [imagingChest, setImagingChest] = useState(false);
  const [imagingCT, setImagingCT] = useState(false);
  const [imagingPET, setImagingPET] = useState(false);
  const [imagingMRI, setImagingMRI] = useState(false);
  const [imagingBone, setImagingBone] = useState(false);
  const [imagingBiospy, setImagingBiospy] = useState(false);
  const [imagingHistological, setImagingHistological] = useState(false);
  const [imagingBacktoLung, setImagingBacktoLung] = useState(false);
  const [biopsyMediastinoscopy, setBiopsyMediastinoscopy] = useState(false);
  const [biospyThorascopy, setBiospyThorascopy] = useState(false);
  const [biopsyEbus, setBiopsyEbus] = useState(false);
  const [biopsyEus, setBiopsyEus] = useState(false);
  const [biopsyCT, setBiopsyCT] = useState(false);
  const [biopsyImaging, setBiopsyImaging] = useState(false);
  const [biopsyHistological, setBiopsyHistological] = useState(false);
  const [biopsyBackToLung, setBiopsyBackToLung] = useState(false);
  const [histologicalImaging, setHistologicalImaging] = useState(false);
  const [histologicalBiopsy, setHistologicalBiopsy] = useState(false);
  const [histologicalBackToLung, setHistologicalBackToLung] = useState(false);

  //HISTOLOGICAL POP UPS
  const [histologicalAdeno, setHistologicalAdeno] = useState(false);
  const [histologicalSquamous, setHistologicalSquamous] = useState(false);
  const [histologicalLarge, setHistologicalLarge] = useState(false);

  //HISTOLOGICAL POP UPS CLOSE BUTTONS
  const [histologicalAdenoClose, setHistologicalAdenoClose] = useState(false);
  const [histologicalSquamousClose, setHistologicalSquamousClose] = useState(false);
  const [histologicalLargeClose, setHistologicalLargeClose] = useState(false);

  //ASSES STAGE
  const [assessStagectnm, setAssessStagectnm] = useState(false);
  const [assessStageptnm, setAssessStageptnm] = useState(false);
  const [assesStageBegin, setAssesStageBegin] = useState(false);

  //ASSESS STAGE BEGIN POP UPS
  const [assessStagectnmClose, setAssessStagectnmClose] = useState(false);
  const [assessStageptnmClose, setAssessStageptnmClose] = useState(false);

  //ASSESS STAGE LINEAR
  const [assessStagePrimaryTumor, setassessStagePrimaryTumor] = useState(false);
  const [assessStagePrimaryTumor2, setassessStagePrimaryTumor2] = useState(false);
  const [assessStagePrimaryTumorReview, setassessStagePrimaryTumorReview] = useState(false);
  const [assessStagePrimaryTumorReviewClose, setassessStagePrimaryTumorReviewClose] =
    useState(false);
  const [assessStagePrimaryTumorNext, setassessStagePrimaryTumorNext] = useState(false);
  const [assessStageNRegional, setassessStageNRegional] = useState(false);
  const [assessStageNReviewAll, setAssessStageNReviewAll] = useState(false);
  const [assessStageNReivewAllClose, setAssessStageNReivewAllClose] = useState(false);
  const [assessStageFinally, setAssessStageFinally] = useState(false);
  const [assessMetaStart, setAssessMetaStart] = useState(false);

  const [assessMetaReview, setAssessMetaReview] = useState(false);
  const [assessMetaCalculate, setAssessMetaCalculate] = useState(false);
  const [assessMetaReviewClose, setAssessMetaReviewClose] = useState(false);
  const [assessMetaTest, setAssessMetaTest] = useState(false);

  const [biomarkerLearnNSCLC, setBiomarkerLearnNSCLC] = useState(false);
  const [biomarkerCollectSample, setBiomarkerCollectSample] = useState(false);

  const [biomarkerTumorBiopsy, setBiomarkerTumorBiopsy] = useState(false);
  const [biomarkerLiquidBiopsy, setBiomarkerLiquidBiopsy] = useState(false);

  //BIOPSYS
  const [tumorBiopsy22Gauge, setTumorBiopsy22Gauge] = useState(false);
  const [tumorBiopsyEndoscope, setTumorBiopsyEndoscope] = useState(false);
  const [tumorBiopsyMediastionscope, setTumorBiopsyMediastionscope] = useState(false);

  //BIOPSYSS SAMPLES
  const [tumorBiopsy22GaugeTakeSample, setTumorBiopsy22GaugeTakeSample] = useState(false);
  const [tumorBiopsyEndoscopeTakeSample, setTumorBiopsyEndoscopeTakeSample] = useState(false);
  const [tumorBiopsyMediaTakeSample, setTumorBiopsyMediaTakeSample] = useState(false);

  //LIQUIDBIOPOSY
  const [liquidBiopsyNeedle, setLiquidBiopsyNeedle] = useState(false);
  const [liquidBiopsyNeedleTakeSample, setLiquidBiopsyNeedleTakeSample] = useState(false);

  //ANEMIA HOT SPOTS START
  const [screenForIDA, setScreenForIDA] = useState(false);
  const [screeningCBCWithout, setScreeningCBCWithout] = useState(false);
  const [screeningCBCWith, setScreeningCBCWith] = useState(false);
  const [screeningCBCWithoutReflex, setScreeningCBCWithoutReflex] = useState(false);
  const [screeningCBCWithReflex, setScreeningCBCWithReflex] = useState(false);
  const [screeningIronPanel, setScreeningIronPanel] = useState(false);

  const [cbcWithoutDiffNeedle, setCbcWithoutDiffNeedle] = useState(false);
  const [performCBCWithDiff, setPerformCBCWithDiff] = useState(false);

  //CBC WITHOUT DIFFERENTIAL IRON PANEL WORK
  const [cbcWithoutDiffNeedleLoop, setCbcWithoutDiffNeedleLoop] = useState(false);
  const [cbcWithoutDiffNeedeLoopText, setCbcWithoutDiffNeedeLoopText] = useState(false);

  const [cbcWithoutDiffReviewTestReport, setCbcWithoutDiffReviewTestReport] = useState(false);
  const [cbcWithoutDiffReviewTestReportClose, setCbcWithoutDiffReviewTestReportClose] =
    useState(false);
  const [cbcWithoutDiffIronPanel, setCbcWithoutDiffIronPanel] = useState(false);
  const [cbcWithoutDiffAnemiaPanel, setCbcWithoutDiffAnemiaPanel] = useState(false);
  const [cbcWithoutDiffVitaminDefTest, setCbcWithoutDiffVitaminDefTest] = useState(false);

  const [cbcWithoutDiffIronPanelTakeSample, setCbcWithoutDiffIronPanelTakeSample] = useState(false);
  const [cbcWithoutDiffIronPanelNeedle, setCbcWithoutDiffIronPanelNeedle] = useState(false);
  const [cbcWithoutDiffIronPanelNeedleText, setCbcWithoutDiffIronPanelNeedleText] = useState(false);

  const [cbcWithoutDiffIronPanelTestReport, setCbcWithoutDiffIronPanelTestReport] = useState(false);
  const [cbcWithoutDiffIronPanelTestReportClose, setCbcWithoutDiffIronPanelTestReportClose] =
    useState(false);
  const [cbcWithoutDiffIronPanelMakeDiagnosis, setCbcWithoutDiffIronPanelMakeDiagnosis] =
    useState(false);
  const [cbcWithoutDiffIronPanelGoBackSelect, setCbcWithoutDiffIronPanelGoBackSelect] =
    useState(false);
  const [cbcWithoutDiffIronPanelGoBackScreen, setCbcWithoutDiffIronPanelGoBackScreen] =
    useState(false);

  //CDC WITHOUT DIFFERENTIAL ANEMIA WORK
  const [cbcWithoutDiffAnemiaNeedle, setCbcWithoutDiffAnemiaNeedle] = useState(false);
  const [cbcWithoutDiffAnemiaNeedleLoop, setcbcWithoutDiffAnemiaNeedleLoop] = useState(false);
  const [cbcWithoutDiffAnemiaNeedleText, setcbcWithoutDiffAnemiaNeedleText] = useState(false);

  const [cbcWithoutDiffAnemiaPanelTestReport, setCbcWithoutDiffAnemiaPanelTestReport] =
    useState(false);
  const [cbcWithoutDiffAnemiaPanelTestReportClose, setCbcWithoutDiffAnemiaPanelTestReportClose] =
    useState(false);
  const [cbcWithoutDiffAnemiaPanelMakeDiagnosis, setCbcWithoutDiffAnemiaPanelMakeDiagnosis] =
    useState(false);
  const [cbcWithoutDiffAnemiaPanelGoBackSelect, setCbcWithoutDiffAnemiaPanelGoBackSelect] =
    useState(false);
  const [cbcWithoutDiffAnemiaPanelGoBackScreen, setCbcWithoutDiffAnemiaPanelGoBackScreen] =
    useState(false);

  //CDC WITHOUT DIFFERENTIAL VITAMIN WORK
  const [cbcWithoutDiffVitaminNeedle, setCbcWithoutDiffVitaminNeedle] = useState(false);
  const [cbcWithoutDiffVitaminNeedleLoop, setcbcWithoutDiffVitaminNeedleLoop] = useState(false);
  const [cbcWithoutDiffVitaminNeedleText, setcbcWithoutDiffVitaminNeedleText] = useState(false);

  const [cbcWithoutDiffVitaminPanelTestReport, setCbcWithoutDiffVitaminPanelTestReport] =
    useState(false);
  const [cbcWithoutDiffVitaminPanelTestReportClose, setCbcWithoutDiffVitaminPanelTestReportClose] =
    useState(false);
  const [cbcWithoutDiffVitaminPanelMakeDiagnosis, setCbcWithoutDiffVitaminPanelMakeDiagnosis] =
    useState(false);
  const [cbcWithoutDiffVitaminPanelGoBackSelect, setCbcWithoutDiffVitaminPanelGoBackSelect] =
    useState(false);
  const [cbcWithoutDiffVitaminPanelGoBackScreen, setCbcWithoutDiffVitaminPanelGoBackScreen] =
    useState(false);

  //IDA DIAGNOSIS WORK
  const [idaDiagnosisCancer, setIdaDiagnosisCancer] = useState(false);
  const [idaDiagnosisCancerClose, setIdaDiagnosisCancerClose] = useState(false);
  const [idaDiagnosisCKD, setIdaDiagnosisCKD] = useState(false);
  const [idaDiagnosisCKDClose, setIdaDiagnosisCKDClose] = useState(false);
  const [idaDiagnosisGiDisorders, setIdaDiagnosisGiDisorders] = useState(false);
  const [idaDiagnosisGiDisordersClose, setIdaDiagnosisGiDisordersClose] = useState(false);
  const [idaDiagnosisHF, setIdaDiagnosisHF] = useState(false);
  const [idaDiagnosisHFClose, setIdaDiagnosisHFClose] = useState(false);

  const [idaDiagnosisCbcWith, setIdaDiagnosisCbcWith] = useState(false);
  const [idaDiagnosisCbcWithoutReflex, setIdaDiagnosisCbcWithoutReflex] = useState(false);
  const [idaDiagnosisCbcWithReflex, setIdaDiagnosisCbcWithReflex] = useState(false);
  const [idaDiagnosisIronPanel, setIdaDiagnosisIronPanel] = useState(false);

  //CBC WITH DIFFERENTIAL
  const [cbcWithDiffNeedle, setCbcWithDiffNeedle] = useState(false);
  const [cbcWithDiffNeedleLoop, setcbcWithDiffNeedleLoop] = useState(false);
  const [cbcWithDiffNeedleText, setcbcWithDiffNeedleText] = useState(false);

  const [cbcWithDiffTestReport, setCbcWithDiffTestReport] = useState(false);
  const [cbcWithDiffTestReportClose, setCbcWithDiffTestReportClose] = useState(false);
  const [cbcWithDiffIronPanel, setCbcWithDiffIronPanel] = useState(false);
  const [cbcWithDiffAnemiaPanel, setCbcWithDiffAnemiaPanel] = useState(false);
  const [cbcWithDiffVitaminDefTest, setCbcWithDiffVitaminDefTest] = useState(false);

  const [cbcWithDiffIronPanelTakeSample, setCbcWithDiffIronPanelTakeSample] = useState(false);
  const [cbcWithDiffIronPanelNeedle, setCbcWithDiffIronPanelNeedle] = useState(false);
  const [cbcWithDiffIronPanelNeedleText, setCbcWithDiffIronPanelNeedleText] = useState(false);

  const [cbcWithDiffIronPanelTestReport, setCbcWithDiffIronPanelTestReport] = useState(false);
  const [cbcWithDiffIronPanelTestReportClose, setCbcWithDiffIronPanelTestReportClose] =
    useState(false);
  const [cbcWithDiffIronPanelMakeDiagnosis, setCbcWithDiffIronPanelMakeDiagnosis] = useState(false);
  const [cbcWithDiffIronPanelGoBackSelect, setCbcWithDiffIronPanelGoBackSelect] = useState(false);
  const [cbcWithDiffIronPanelGoBackScreen, setCbcWithDiffIronPanelGoBackScreen] = useState(false);

  //CDC WITHOUT DIFFERENTIAL ANEMIA WORK
  const [cbcWithDiffAnemiaNeedle, setCbcWithDiffAnemiaNeedle] = useState(false);
  const [cbcWithDiffAnemiaNeedleLoop, setcbcWithDiffAnemiaNeedleLoop] = useState(false);
  const [cbcWithDiffAnemiaNeedleText, setcbcWithDiffAnemiaNeedleText] = useState(false);

  const [cbcWithDiffAnemiaPanelTestReport, setCbcWithDiffAnemiaPanelTestReport] = useState(false);
  const [cbcWithDiffAnemiaPanelTestReportClose, setCbcWithDiffAnemiaPanelTestReportClose] =
    useState(false);
  const [cbcWithDiffAnemiaPanelMakeDiagnosis, setCbcWithDiffAnemiaPanelMakeDiagnosis] =
    useState(false);
  const [cbcWithDiffAnemiaPanelGoBackSelect, setCbcWithDiffAnemiaPanelGoBackSelect] =
    useState(false);
  const [cbcWithDiffAnemiaPanelGoBackScreen, setCbcWithDiffAnemiaPanelGoBackScreen] =
    useState(false);

  //CDC WITHOUT DIFFERENTIAL VITAMIN WORK
  const [cbcWithDiffVitaminNeedle, setCbcWithDiffVitaminNeedle] = useState(false);
  const [cbcWithDiffVitaminNeedleLoop, setcbcWithDiffVitaminNeedleLoop] = useState(false);
  const [cbcWithDiffVitaminNeedleText, setcbcWithDiffVitaminNeedleText] = useState(false);

  const [cbcWithDiffVitaminPanelTestReport, setCbcWithDiffVitaminPanelTestReport] = useState(false);
  const [cbcWithDiffVitaminPanelTestReportClose, setCbcWithDiffVitaminPanelTestReportClose] =
    useState(false);
  const [cbcWithDiffVitaminPanelMakeDiagnosis, setCbcWithDiffVitaminPanelMakeDiagnosis] =
    useState(false);
  const [cbcWithDiffVitaminPanelGoBackSelect, setCbcWithDiffVitaminPanelGoBackSelect] =
    useState(false);
  const [cbcWithDiffVitaminPanelGoBackScreen, setCbcWithDiffVitaminPanelGoBackScreen] =
    useState(false);

  //IDA DIAGNOSIS WORK
  const [cbcWithIdaDiagnosisCancer, setcbcWithIdaDiagnosisCancer] = useState(false);
  const [cbcWithIdaDiagnosisCancerClose, setcbcWithIdaDiagnosisCancerClose] = useState(false);
  const [cbcWithIdaDiagnosisCKD, setcbcWithIdaDiagnosisCKD] = useState(false);
  const [cbcWithIdaDiagnosisCKDClose, setcbcWithIdaDiagnosisCKDClose] = useState(false);
  const [cbcWithIdaDiagnosisGiDisorders, setcbcWithIdaDiagnosisGiDisorders] = useState(false);
  const [cbcWithIdaDiagnosisGiDisordersClose, setcbcWithIdaDiagnosisGiDisordersClose] =
    useState(false);
  const [cbcWithIdaDiagnosisHF, setcbcWithIdaDiagnosisHF] = useState(false);
  const [cbcWithIdaDiagnosisHFClose, setcbcWithIdaDiagnosisHFClose] = useState(false);

  const [cbcWithIdaDiagnosisCbcWithout, setcbcWithoutIdaDiagnosisCbcWithout] = useState(false);
  const [cbcWithIdaDiagnosisCbcWithoutReflex, setcbcWithIdaDiagnosisCbcWithoutReflex] =
    useState(false);
  const [cbcWithIdaDiagnosisCbcWithReflex, setcbcWithIdaDiagnosisCbcWithReflex] = useState(false);
  const [cbcWithIdaDiagnosisIronPanel, setcbcWithIdaDiagnosisIronPanel] = useState(false);

  //CBC WITHOUT DIFFERENTIAL REFLEX TO ANEMIA
  const [cbcWithoutReflexNeedle, setCbcWithoutReflexNeedle] = useState(false);
  const [cbcWithoutReflexPerformCBCWithReflex, setCbcWithoutReflexPerformCBCWithReflex] =
    useState(false);
  const [cbcWithoutReflexNeedleLoop, setcbcWithoutReflexNeedleLoop] = useState(false);
  const [cbcWithoutReflexNeedleText, setcbcWithoutReflexNeedleText] = useState(false);

  const [cbcWithoutReflexTestReport, setCbcWithoutReflexTestReport] = useState(false);
  const [cbcWithoutReflexTestReportClose, setCbcWithoutReflexTestReportClose] = useState(false);
  const [cbcWithoutReflexMakeDiagnosis, setCbcWithoutReflexMakeDiagnosis] = useState(false);
  const [cbcWithoutReflexGoBackScreen, setCbcWithoutReflexGoBackScreen] = useState(false);

  const [cbcWithoutReflexIdaDiagnosisCancer, setcbcWithoutReflexIdaDiagnosisCancer] =
    useState(false);
  const [cbcWithoutReflexIdaDiagnosisCancerClose, setcbcWithoutReflexIdaDiagnosisCancerClose] =
    useState(false);
  const [cbcWithoutReflexIdaDiagnosisCKD, setcbcWithoutReflexIdaDiagnosisCKD] = useState(false);
  const [cbcWithoutReflexIdaDiagnosisCKDClose, setcbcWithoutReflexIdaDiagnosisCKDClose] =
    useState(false);
  const [cbcWithoutReflexIdaDiagnosisGiDisorders, setcbcWithoutReflexIdaDiagnosisGiDisorders] =
    useState(false);
  const [
    cbcWithoutReflexIdaDiagnosisGiDisordersClose,
    setcbcWithoutReflexIdaDiagnosisGiDisordersClose
  ] = useState(false);
  const [cbcWithoutReflexIdaDiagnosisHF, setcbcWithoutReflexIdaDiagnosisHF] = useState(false);
  const [cbcWithoutReflexIdaDiagnosisHFClose, setcbcWithoutReflexIdaDiagnosisHFClose] =
    useState(false);

  const [cbcWithoutReflexIdaDiagnosisCbcWithout, setcbcWithoutReflexIdaDiagnosisCbcWithout] =
    useState(false);
  const [cbcWithoutReflexIdaDiagnosisCbcWith, setcbcWithoutReflexIdaDiagnosisCbcWith] =
    useState(false);
  const [cbcWithoutReflexIdaDiagnosisCbcWithReflex, setcbcWithoutReflexIdaDiagnosisCbcWithReflex] =
    useState(false);
  const [cbcWithoutReflexIdaDiagnosisIronPanel, setcbcWithoutReflexIdaDiagnosisIronPanel] =
    useState(false);

  //CBC WITH DIFFERENTIAL REFLEX TO ANEMIA
  const [cbcWithReflexNeedle, setCbcWithReflexNeedle] = useState(false);
  const [cbcWithReflexNeedleLoop, setcbcWithReflexNeedleLoop] = useState(false);
  const [cbcWithReflexNeedleText, setcbcWithReflexNeedleText] = useState(false);

  const [cbcWithReflexTestReport, setCbcWithReflexTestReport] = useState(false);
  const [cbcWithReflexTestReportClose, setCbcWithReflexTestReportClose] = useState(false);
  const [cbcWithReflexMakeDiagnosis, setCbcWithReflexMakeDiagnosis] = useState(false);
  const [cbcWithReflexGoBackScreen, setCbcWithReflexGoBackScreen] = useState(false);

  const [cbcWithReflexIdaDiagnosisCancer, setcbcWithReflexIdaDiagnosisCancer] = useState(false);
  const [cbcWithReflexIdaDiagnosisCancerClose, setcbcWithReflexIdaDiagnosisCancerClose] =
    useState(false);
  const [cbcWithReflexIdaDiagnosisCKD, setcbcWithReflexIdaDiagnosisCKD] = useState(false);
  const [cbcWithReflexIdaDiagnosisCKDClose, setcbcWithReflexIdaDiagnosisCKDClose] = useState(false);
  const [cbcWithReflexIdaDiagnosisGiDisorders, setcbcWithReflexIdaDiagnosisGiDisorders] =
    useState(false);
  const [cbcWithReflexIdaDiagnosisGiDisordersClose, setcbcWithReflexIdaDiagnosisGiDisordersClose] =
    useState(false);
  const [cbcWithReflexIdaDiagnosisHF, setcbcWithReflexIdaDiagnosisHF] = useState(false);
  const [cbcWithReflexIdaDiagnosisHFClose, setcbcWithReflexIdaDiagnosisHFClose] = useState(false);

  const [cbcWithReflexIdaDiagnosisCbcWithout, setcbcWithReflexIdaDiagnosisCbcWithout] =
    useState(false);
  const [cbcWithReflexIdaDiagnosisCbcWith, setcbcWithReflexIdaDiagnosisCbcWith] = useState(false);
  const [cbcWithReflexIdaDiagnosisCbcWithoutReflex, setcbcWithReflexIdaDiagnosisCbcWithoutReflex] =
    useState(false);
  const [cbcWithReflexIdaDiagnosisIronPanel, setcbcWithReflexIdaDiagnosisIronPanel] =
    useState(false);

  //IRON PANEL WORK
  const [ironPanelNeedle, setironPanelNeedle] = useState(false);
  const [ironPanelNeedleLoop, setironPanelNeedleLoop] = useState(false);
  const [ironPanelNeedleText, setironPanelNeedleText] = useState(false);

  const [ironPanelTestReport, setironPanelTestReport] = useState(false);
  const [ironPanelTestReportClose, setironPanelTestReportClose] = useState(false);
  const [ironPanelMakeDiagnosis, setironPanelMakeDiagnosis] = useState(false);
  const [ironPanelGoBackScreen, setironPanelGoBackScreen] = useState(false);

  const [ironPanelIdaDiagnosisCbcWithout, setironPanelIdaDiagnosisCbcWithout] = useState(false);
  const [ironPanelIdaDiagnosisCbcWith, setironPanelIdaDiagnosisCbcWith] = useState(false);
  const [ironPanelIdaDiagnosisCbcWithoutReflex, setironPanelIdaDiagnosisCbcWithoutReflex] =
    useState(false);
  const [ironPanelIdaDiagnosisWithReflex, setironPanelIdaDiagnosisWithReflex] = useState(false);

  //TIPPY HOVER STATES
  const [hemoglobinConcHome, setHemoglobinConcHome] = useState(false);
  const [hematocritHome, sethematocritHome] = useState(false);
  const [rbcCountHome, setrbcCountHome] = useState(false);
  const [plateletCountCbcWithout, setplateletCountCbcWithout] = useState(false);
  const [wbccountCbcbWithout, setwbccountCbcbWithout] = useState(false);
  const [ferritinCbcWithoutIron, setferritinCbcWithoutIron] = useState(false);
  const [tibcCbcWithoutIron, settibcCbcWithoutIron] = useState(false);
  const [serumIronCbcWithoutIron, setserumIronCbcWithoutIron] = useState(false);
  const [tsatCbcWithoutIron, settsatCbcWithoutIron] = useState(false);
  const [functionalIronCbcWithoutIron, setfunctionalIronCbcWithoutIron] = useState(false);
  const [vitaminb12CbcWithoutAnemia, setvitaminb12CbcWithoutAnemia] = useState(false);
  const [folateCbcWithoutAnemia, setfolateCbcWithoutAnemia] = useState(false);
  const [retiCountCbcWithoutAnemia, setretiCountCbcWithoutAnemia] = useState(false);

  const [plateletCountCbcWith, setplateletCountCbcWith] = useState(false);
  const [wbcCountCbcWith, setwbcCountCbcWith] = useState(false);
  const [neutrophilsCbcWith, setneutrophilsCbcWith] = useState(false);
  const [lymphocytesCbcWith, setlymphocytesCbcWith] = useState(false);
  const [monocytesCbcWith, setmonocytesCbcWith] = useState(false);
  const [eosinophilsCbcWith, seteosinophilsCbcWith] = useState(false);
  const [basophilsCbcWith, setbasophilsCbcWith] = useState(false);

  const [ferritinCbcWithIron, setferritinCbcWithIron] = useState(false);
  const [tibcCbcWithIron, settibcCbcWithIron] = useState(false);
  const [serumIronCbcWithIron, setserumIronCbcWithIron] = useState(false);
  const [tsatCbcWithIron, settsatCbcWithIron] = useState(false);
  const [functionalIronCbcWithIron, setfunctionalIronCbcWithIron] = useState(false);

  //just realized each of these hotspots dont need individual states, just one for each section
  const [tippyCbcWithAnemia, setTippyCbcWithAnemia] = useState(false);
  const [tippyCbcWithoutReflex, setTippyCbcWithoutReflex] = useState(false);
  const [tippyCbcWithReflex, setTippyCbcWithReflex] = useState(false);
  const [tippyCbcWithReflexPanel, setTippyCbcWithReflexPanel] = useState(false);
  const [tippyIronPanel, setTippyIronPanel] = useState(false);
  const [tippyCbcWithoutReflexPanel, setTippyCbcWithoutReflexPanel] = useState(false);

  //MODAL HOTSPOTS
  const [cbcWithModalOpen, setcbcWithModalOpen] = useState(false);
  const [cbcWithoutReflexmodalOpen, setcbcWithoutReflexModalOpen] = useState(false);
  const [cbcWithReflexmodalOpen, setcbcWithReflexModalOpen] = useState(false);

  const setCurrentVideoTime = (newTime) => {
    videoEl.current[currentIndex].currentTime = newTime;
    videoEl.current[currentIndex].play();
  };

  useEffect(() => {
    //START BREAST HOT SPOTS
    setIsHotSpot2Active(
      currentTime >= 18.0 && currentTime < 19.0 && currentIndex === videoIndexs.BREAST
    );
    setIsHotSpot3Active(
      currentTime >= 18.0 && currentTime < 19.0 && currentIndex === videoIndexs.BREAST
    );
    setcTNMHotSpotActive(
      currentTime >= 22.0 && currentTime < 23.0 && currentIndex === videoIndexs.BREAST
    );
    setpTNMHotSpotActive(
      currentTime >= 22.0 && currentTime < 23.0 && currentIndex === videoIndexs.BREAST
    );
    setAssessPrimaryHotSpotActive(
      currentTime >= 22.0 && currentTime < 23.0 && currentIndex === videoIndexs.BREAST
    );
    setClosePopupActivecTNM(
      currentTime >= 25.0 && currentTime < 26.0 && currentIndex === videoIndexs.BREAST
    );
    setClosePopupActivepTNM(
      currentTime >= 27.0 && currentTime < 28.0 && currentIndex === videoIndexs.BREAST
    );
    setMeasureTumorHotSpotActive(
      currentTime >= 30.0 && currentTime < 43.0 && currentIndex === videoIndexs.BREAST
    );
    setMeasureTumorTwoHotSpotActive(
      currentTime >= 43.0 && currentTime < 63.0 && currentIndex === videoIndexs.BREAST
    );
    setReviewTCategoriesHotSpotActive(
      currentTime >= 76.0 && currentTime < 77.0 && currentIndex === videoIndexs.BREAST
    );
    setNextNodalAssessHotSpotActive(
      currentTime >= 76.0 && currentTime < 77.0 && currentIndex === videoIndexs.BREAST
    );
    setClosePopupActiveTumoranatomic(
      currentTime >= 78.0 && currentTime < 79.0 && currentIndex === videoIndexs.BREAST
    );
    setNAssesRegionalHotSpotActive(
      currentTime >= ONE_MINUTE + 22.0 &&
        currentTime < ONE_MINUTE + 35.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setReviewAllCNHotSpotActive(
      currentTime >= ONE_MINUTE + 42.0 &&
        currentTime < ONE_MINUTE + 43.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setFinallyAssesSpotActive(
      currentTime >= ONE_MINUTE + 42.0 &&
        currentTime < ONE_MINUTE + 43.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setClinicalAnatomicPopUpActive(
      currentTime >= ONE_MINUTE + 44.0 &&
        currentTime < ONE_MINUTE + 45.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setImagingHotSpotActive(
      currentTime >= ONE_MINUTE + 48.0 &&
        currentTime < TWO_MINUTES + 1.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setBodyScanStartHotSpotActive(
      currentTime >= ONE_MINUTE + 48.0 &&
        currentTime < TWO_MINUTES + 1.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setRadiographicPopUpActive(
      currentTime >= TWO_MINUTES + 8.0 &&
        currentTime < TWO_MINUTES + 9.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setReviewAllMHotSpotActive(
      currentTime >= TWO_MINUTES + 19.0 &&
        currentTime < TWO_MINUTES + 20.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setCalculateStageHotSpotActive(
      currentTime >= TWO_MINUTES + 19.0 &&
        currentTime < TWO_MINUTES + 20.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setDistantMetastasesPopUpActive(
      currentTime >= TWO_MINUTES + 21.0 &&
        currentTime < TWO_MINUTES + 22.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setIsDetermineHer2HotSpotActive(
      currentTime >= TWO_MINUTES + 24.0 &&
        currentTime < TWO_MINUTES + 25.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setTumorBiopsyHotSpotActive(
      currentTime >= TWO_MINUTES + 25.0 &&
        currentTime < TWO_MINUTES + 26.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setLiquidBiopsyHotSpotActive(
      currentTime >= TWO_MINUTES + 25.0 &&
        currentTime < TWO_MINUTES + 26.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setTwentyTwoGuageHotSpotActive(
      currentTime >= TWO_MINUTES + 31.0 &&
        currentTime < TWO_MINUTES + 34.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setFourteenGuageHotSpotActive(
      currentTime >= TWO_MINUTES + 31.0 &&
        currentTime < TWO_MINUTES + 34.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setScalpelHotSpotActive(
      currentTime >= TWO_MINUTES + 31.0 &&
        currentTime < TWO_MINUTES + 34.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setTumorBiopsyNeedleHotSpotActive(
      currentTime >= TWO_MINUTES + 41.0 &&
        currentTime < TWO_MINUTES + 54.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setFourteenGuageTumorHotSpotActive(
      currentTime >= THREE_MINUTES + 4.0 &&
        currentTime < THREE_MINUTES + 17.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setScalpelTumorHotSpotActive(
      currentTime >= THREE_MINUTES + 22.0 &&
        currentTime < THREE_MINUTES + 38.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setLiquidBiopsyNGSHotSpotActive(
      currentTime >= SIX_MINUTES + 1.0 &&
        currentTime < SIX_MINUTES + 6.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setLiquidBiopsySampleHotSpotActive(
      currentTime >= SIX_MINUTES + 12.0 &&
        currentTime < SIX_MINUTES + 26.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setIHCTestingHotSpotActive(
      currentTime >= THREE_MINUTES + 45.0 &&
        currentTime < THREE_MINUTES + 48.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setISHTestingHotSpotActive(
      currentTime >= THREE_MINUTES + 45.0 &&
        currentTime < THREE_MINUTES + 48.0 &&
        currentIndex === videoIndexs.BREAST
    );
    setNGSTestingHotSpotActive(
      currentTime >= THREE_MINUTES + 45.0 &&
        currentTime < THREE_MINUTES + 48.0 &&
        currentIndex === videoIndexs.BREAST
    );

    //START LUNG HOT SPOTS
    setLungCancerIntro(
      currentTime >= 12.0 && currentTime < 26.0 && currentIndex === videoIndexs.INTRO
    );
    setBreastCancerIntro(
      currentTime >= 12.0 && currentTime < 26.0 && currentIndex === videoIndexs.INTRO
    );
    setAnemiaIntro(currentTime >= 12.0 && currentTime < 26.0 && currentIndex === videoIndexs.INTRO);
    setExploreComponents(
      currentTime >= 28.0 && currentTime < 30.0 && currentIndex === videoIndexs.LUNG
    );
    setAssesStage(currentTime >= 28.0 && currentTime < 30.0 && currentIndex === videoIndexs.LUNG);
    setTestBiomarkers(
      currentTime >= 28.0 && currentTime < 30.0 && currentIndex === videoIndexs.LUNG
    );
    setComponentsImaging(
      currentTime >= 38.0 && currentTime < 41.0 && currentIndex === videoIndexs.LUNG
    );
    setComponentsBiopsy(
      currentTime >= 38.0 && currentTime < 41.0 && currentIndex === videoIndexs.LUNG
    );
    setComponentsHistological(
      currentTime >= 38.0 && currentTime < 41.0 && currentIndex === videoIndexs.LUNG
    );
    setComponentsBackToLung(
      currentTime >= 38.0 && currentTime < 41.0 && currentIndex === videoIndexs.LUNG
    );
    setImagingChest(currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG);
    setImagingCT(currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG);
    setImagingPET(currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG);
    setImagingMRI(currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG);
    setImagingBone(currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG);
    setImagingBiospy(
      currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG
    );
    setImagingHistological(
      currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG
    );
    setImagingBacktoLung(
      currentTime >= 50.0 && currentTime < 52.0 && currentIndex === videoIndexs.LUNG
    );
    setBiopsyMediastinoscopy(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiospyThorascopy(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyEbus(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyEus(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyCT(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyImaging(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyHistological(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyBackToLung(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiopsyMediastinoscopy(
      currentTime >= ONE_MINUTE + 4 &&
        currentTime < ONE_MINUTE + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalAdeno(
      currentTime >= ONE_MINUTE + 18 &&
        currentTime < ONE_MINUTE + 20 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalSquamous(
      currentTime >= ONE_MINUTE + 18 &&
        currentTime < ONE_MINUTE + 20 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalLarge(
      currentTime >= ONE_MINUTE + 18 &&
        currentTime < ONE_MINUTE + 20 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalImaging(
      currentTime >= ONE_MINUTE + 18 &&
        currentTime < ONE_MINUTE + 20 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalBiopsy(
      currentTime >= ONE_MINUTE + 18 &&
        currentTime < ONE_MINUTE + 20 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalBackToLung(
      currentTime >= ONE_MINUTE + 18 &&
        currentTime < ONE_MINUTE + 20 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalAdenoClose(
      currentTime >= ONE_MINUTE + 21 &&
        currentTime < ONE_MINUTE + 26 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalSquamousClose(
      currentTime >= ONE_MINUTE + 38 &&
        currentTime < ONE_MINUTE + 45 &&
        currentIndex === videoIndexs.LUNG
    );
    setHistologicalLargeClose(
      currentTime >= ONE_MINUTE + 31 &&
        currentTime < ONE_MINUTE + 36 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStagectnm(
      currentTime >= ONE_MINUTE + 48 &&
        currentTime < ONE_MINUTE + 51 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStageptnm(
      currentTime >= ONE_MINUTE + 48 &&
        currentTime < ONE_MINUTE + 51 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssesStageBegin(
      currentTime >= ONE_MINUTE + 48 &&
        currentTime < ONE_MINUTE + 51 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStagectnmClose(
      currentTime >= ONE_MINUTE + 52 &&
        currentTime < ONE_MINUTE + 54 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStageptnmClose(
      currentTime >= ONE_MINUTE + 54 &&
        currentTime < ONE_MINUTE + 56 &&
        currentIndex === videoIndexs.LUNG
    );
    setassessStagePrimaryTumor(
      currentTime >= ONE_MINUTE + 59 &&
        currentTime < TWO_MINUTES + 13 &&
        currentIndex === videoIndexs.LUNG
    );
    setassessStagePrimaryTumor2(
      currentTime >= TWO_MINUTES + 20 &&
        currentTime < TWO_MINUTES + 32 &&
        currentIndex === videoIndexs.LUNG
    );
    setassessStagePrimaryTumorReview(
      currentTime >= TWO_MINUTES + 37 &&
        currentTime < TWO_MINUTES + 39 &&
        currentIndex === videoIndexs.LUNG
    );
    setassessStagePrimaryTumorReviewClose(
      currentTime >= TWO_MINUTES + 42 &&
        currentTime < TWO_MINUTES + 43 &&
        currentIndex === videoIndexs.LUNG
    );
    setassessStagePrimaryTumorNext(
      currentTime >= TWO_MINUTES + 37 &&
        currentTime < TWO_MINUTES + 39 &&
        currentIndex === videoIndexs.LUNG
    );
    setassessStageNRegional(
      currentTime >= TWO_MINUTES + 49 &&
        currentTime < THREE_MINUTES + 4 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStageNReviewAll(
      currentTime >= THREE_MINUTES + 10 &&
        currentTime < THREE_MINUTES + 13 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStageNReivewAllClose(
      currentTime >= THREE_MINUTES + 15 &&
        currentTime < THREE_MINUTES + 16 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessStageFinally(
      currentTime >= THREE_MINUTES + 10 &&
        currentTime < THREE_MINUTES + 13 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessMetaStart(
      currentTime >= THREE_MINUTES + 20 &&
        currentTime < THREE_MINUTES + 35 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessMetaReview(
      currentTime >= THREE_MINUTES + 52 &&
        currentTime < THREE_MINUTES + 55 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessMetaCalculate(
      currentTime >= THREE_MINUTES + 52 &&
        currentTime < THREE_MINUTES + 55 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessMetaReviewClose(
      currentTime >= THREE_MINUTES + 56 &&
        currentTime < THREE_MINUTES + 57 &&
        currentIndex === videoIndexs.LUNG
    );
    setAssessMetaTest(
      currentTime >= FOUR_MINUTES &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiomarkerLearnNSCLC(
      currentTime >= FOUR_MINUTES + 4 &&
        currentTime < FOUR_MINUTES + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiomarkerCollectSample(
      currentTime >= FOUR_MINUTES + 4 &&
        currentTime < FOUR_MINUTES + 7 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiomarkerTumorBiopsy(
      currentTime >= FOUR_MINUTES + 16 &&
        currentTime < FOUR_MINUTES + 18 &&
        currentIndex === videoIndexs.LUNG
    );
    setBiomarkerLiquidBiopsy(
      currentTime >= FOUR_MINUTES + 16 &&
        currentTime < FOUR_MINUTES + 18 &&
        currentIndex === videoIndexs.LUNG
    );
    setTumorBiopsy22Gauge(
      currentTime >= FOUR_MINUTES + 20 &&
        currentTime < FOUR_MINUTES + 22 &&
        currentIndex === videoIndexs.LUNG
    );
    setTumorBiopsyEndoscope(
      currentTime >= FOUR_MINUTES + 20 &&
        currentTime < FOUR_MINUTES + 22 &&
        currentIndex === videoIndexs.LUNG
    );
    setTumorBiopsyMediastionscope(
      currentTime >= FOUR_MINUTES + 20 &&
        currentTime < FOUR_MINUTES + 22 &&
        currentIndex === videoIndexs.LUNG
    );
    setTumorBiopsy22GaugeTakeSample(
      currentTime >= FOUR_MINUTES + 26 &&
        currentTime < FOUR_MINUTES + 41 &&
        currentIndex === videoIndexs.LUNG
    );
    setTumorBiopsyEndoscopeTakeSample(
      currentTime >= FOUR_MINUTES + 50 &&
        currentTime < FIVE_MINUTES + 2 &&
        currentIndex === videoIndexs.LUNG
    );
    setTumorBiopsyMediaTakeSample(
      currentTime >= FIVE_MINUTES + 19 &&
        currentTime < FIVE_MINUTES + 34 &&
        currentIndex === videoIndexs.LUNG
    );
    setLiquidBiopsyNeedle(
      currentTime >= SIX_MINUTES + 24 &&
        currentTime < SIX_MINUTES + 27 &&
        currentIndex === videoIndexs.LUNG
    );
    setLiquidBiopsyNeedleTakeSample(
      currentTime >= SIX_MINUTES + 36 &&
        currentTime < SIX_MINUTES + 48 &&
        currentIndex === videoIndexs.LUNG
    );

    //START OF ANEMIA SET STATES
    setScreenForIDA(currentTime >= 34 && currentTime < 38 && currentIndex === videoIndexs.ANEMIA);
    setScreeningCBCWithout(
      currentTime >= 40 && currentTime < 43 && currentIndex === videoIndexs.ANEMIA
    );
    setScreeningCBCWith(
      currentTime >= 40 && currentTime < 43 && currentIndex === videoIndexs.ANEMIA
    );
    setScreeningCBCWithoutReflex(
      currentTime >= 40 && currentTime < 43 && currentIndex === videoIndexs.ANEMIA
    );
    setScreeningCBCWithReflex(
      currentTime >= 40 && currentTime < 43 && currentIndex === videoIndexs.ANEMIA
    );
    setScreeningIronPanel(
      currentTime >= 40 && currentTime < 43 && currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffNeedle(
      currentTime >= 48 && currentTime < 50 && currentIndex === videoIndexs.ANEMIA
    );
    setPerformCBCWithDiff(
      currentTime >= 48 && currentTime < 50 && currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffNeedleLoop(
      currentTime >= 59 && currentTime < ONE_MINUTE + 10.0 && currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffNeedeLoopText(
      currentTime >= 59 && currentTime < ONE_MINUTE + 10.0 && currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffReviewTestReport(
      currentTime >= ONE_MINUTE + 22 &&
        currentTime < ONE_MINUTE + 25 &&
        currentIndex === videoIndexs.ANEMIA
    );

    setCbcWithoutDiffIronPanel(
      currentTime >= ONE_MINUTE + 22 &&
        currentTime < ONE_MINUTE + 25 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaPanel(
      currentTime >= ONE_MINUTE + 22 &&
        currentTime < ONE_MINUTE + 25 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminDefTest(
      currentTime >= ONE_MINUTE + 22 &&
        currentTime < ONE_MINUTE + 25 &&
        currentIndex === videoIndexs.ANEMIA
    );

    setCbcWithoutDiffIronPanelTakeSample(
      currentTime >= ONE_MINUTE + 37 &&
        currentTime < ONE_MINUTE + 40 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelNeedle(
      currentTime >= ONE_MINUTE + 45 &&
        currentTime < ONE_MINUTE + 56 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelNeedleText(
      currentTime >= ONE_MINUTE + 45 &&
        currentTime < ONE_MINUTE + 56 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelTestReport(
      currentTime >= TWO_MINUTES + 4 &&
        currentTime < TWO_MINUTES + 7 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelMakeDiagnosis(
      currentTime >= TWO_MINUTES + 4 &&
        currentTime < TWO_MINUTES + 7 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelGoBackSelect(
      currentTime >= TWO_MINUTES + 4 &&
        currentTime < TWO_MINUTES + 7 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelGoBackScreen(
      currentTime >= TWO_MINUTES + 4 &&
        currentTime < TWO_MINUTES + 7 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaNeedle(
      currentTime >= TWO_MINUTES + 17 &&
        currentTime < TWO_MINUTES + 21 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutDiffAnemiaNeedleLoop(
      currentTime >= TWO_MINUTES + 27 &&
        currentTime < TWO_MINUTES + 38 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutDiffAnemiaNeedleText(
      currentTime >= TWO_MINUTES + 27 &&
        currentTime < TWO_MINUTES + 38 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaPanelTestReport(
      currentTime >= TWO_MINUTES + 52 &&
        currentTime < TWO_MINUTES + 55 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaPanelMakeDiagnosis(
      currentTime >= TWO_MINUTES + 52 &&
        currentTime < TWO_MINUTES + 55 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaPanelGoBackSelect(
      currentTime >= TWO_MINUTES + 52 &&
        currentTime < TWO_MINUTES + 55 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaPanelGoBackScreen(
      currentTime >= TWO_MINUTES + 52 &&
        currentTime < TWO_MINUTES + 55 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminNeedle(
      currentTime >= THREE_MINUTES + 8 &&
        currentTime < THREE_MINUTES + 11 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutDiffVitaminNeedleLoop(
      currentTime >= THREE_MINUTES + 19 &&
        currentTime < THREE_MINUTES + 30 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutDiffVitaminNeedleText(
      currentTime >= THREE_MINUTES + 19 &&
        currentTime < THREE_MINUTES + 30 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminPanelTestReport(
      currentTime >= THREE_MINUTES + 41 &&
        currentTime < THREE_MINUTES + 44 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminPanelMakeDiagnosis(
      currentTime >= THREE_MINUTES + 41 &&
        currentTime < THREE_MINUTES + 44 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminPanelGoBackSelect(
      currentTime >= THREE_MINUTES + 41 &&
        currentTime < THREE_MINUTES + 44 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminPanelGoBackScreen(
      currentTime >= THREE_MINUTES + 41 &&
        currentTime < THREE_MINUTES + 44 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCancer(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCKD(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisGiDisorders(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisHF(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCancerClose(
      currentTime >= FOUR_MINUTES + 5 &&
        currentTime < FOUR_MINUTES + 6 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCKDClose(
      currentTime >= FOUR_MINUTES + 10 &&
        currentTime < FOUR_MINUTES + 11 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisGiDisordersClose(
      currentTime >= FOUR_MINUTES + 18 &&
        currentTime < FOUR_MINUTES + 19 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisHFClose(
      currentTime >= FOUR_MINUTES + 23 &&
        currentTime < FOUR_MINUTES + 24 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCbcWith(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCbcWithoutReflex(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisCbcWithReflex(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setIdaDiagnosisIronPanel(
      currentTime >= THREE_MINUTES + 58 &&
        currentTime < FOUR_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffNeedle(
      currentTime >= FOUR_MINUTES + 28 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithDiffNeedleLoop(
      currentTime >= FOUR_MINUTES + 39 &&
        currentTime < FOUR_MINUTES + 50 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithDiffNeedleText(
      currentTime >= FOUR_MINUTES + 39 &&
        currentTime < FOUR_MINUTES + 50 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffTestReport(
      currentTime >= FIVE_MINUTES &&
        currentTime < FIVE_MINUTES + 4 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanel(
      currentTime >= FIVE_MINUTES &&
        currentTime < FIVE_MINUTES + 4 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaPanel(
      currentTime >= FIVE_MINUTES &&
        currentTime < FIVE_MINUTES + 4 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminDefTest(
      currentTime >= FIVE_MINUTES &&
        currentTime < FIVE_MINUTES + 4 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelTakeSample(
      currentTime >= FIVE_MINUTES + 24 &&
        currentTime < FIVE_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelNeedle(
      currentTime >= FIVE_MINUTES + 33 &&
        currentTime < FIVE_MINUTES + 43 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelNeedleText(
      currentTime >= FIVE_MINUTES + 33 &&
        currentTime < FIVE_MINUTES + 43 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelTestReport(
      currentTime >= FIVE_MINUTES + 53 &&
        currentTime < FIVE_MINUTES + 56 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelMakeDiagnosis(
      currentTime >= FIVE_MINUTES + 53 &&
        currentTime < FIVE_MINUTES + 56 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelGoBackSelect(
      currentTime >= FIVE_MINUTES + 53 &&
        currentTime < FIVE_MINUTES + 56 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelGoBackScreen(
      currentTime >= FIVE_MINUTES + 53 &&
        currentTime < FIVE_MINUTES + 56 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaNeedle(
      currentTime >= SIX_MINUTES + 9 &&
        currentTime < SIX_MINUTES + 11 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithDiffAnemiaNeedleLoop(
      currentTime >= SIX_MINUTES + 18 &&
        currentTime < SIX_MINUTES + 29 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithDiffAnemiaNeedleText(
      currentTime >= SIX_MINUTES + 18 &&
        currentTime < SIX_MINUTES + 29 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaPanelTestReport(
      currentTime >= SIX_MINUTES + 46 &&
        currentTime < SIX_MINUTES + 48 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaPanelMakeDiagnosis(
      currentTime >= SIX_MINUTES + 46 &&
        currentTime < SIX_MINUTES + 48 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaPanelGoBackSelect(
      currentTime >= SIX_MINUTES + 46 &&
        currentTime < SIX_MINUTES + 48 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaPanelGoBackScreen(
      currentTime >= SIX_MINUTES + 46 &&
        currentTime < SIX_MINUTES + 48 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminNeedle(
      currentTime >= SIX_MINUTES + 58 &&
        currentTime < SEVEN_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithDiffVitaminNeedleLoop(
      currentTime >= SEVEN_MINUTES + 7 &&
        currentTime < SEVEN_MINUTES + 18 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithDiffVitaminNeedleText(
      currentTime >= SEVEN_MINUTES + 7 &&
        currentTime < SEVEN_MINUTES + 18 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminPanelTestReport(
      currentTime >= SEVEN_MINUTES + 30 &&
        currentTime < SEVEN_MINUTES + 33 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminPanelMakeDiagnosis(
      currentTime >= SEVEN_MINUTES + 30 &&
        currentTime < SEVEN_MINUTES + 33 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminPanelGoBackSelect(
      currentTime >= SEVEN_MINUTES + 30 &&
        currentTime < SEVEN_MINUTES + 33 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminPanelGoBackScreen(
      currentTime >= SEVEN_MINUTES + 30 &&
        currentTime < SEVEN_MINUTES + 33 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisCancer(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisCKD(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisGiDisorders(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisHF(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisCancerClose(
      currentTime >= SEVEN_MINUTES + 45 &&
        currentTime < SEVEN_MINUTES + 46 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisCKDClose(
      currentTime >= SEVEN_MINUTES + 52 &&
        currentTime < SEVEN_MINUTES + 53 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisGiDisordersClose(
      currentTime >= EIGHT_MINUTES &&
        currentTime < EIGHT_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisHFClose(
      currentTime >= EIGHT_MINUTES + 7 &&
        currentTime < EIGHT_MINUTES + 8 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutIdaDiagnosisCbcWithout(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisCbcWithoutReflex(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisCbcWithReflex(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithIdaDiagnosisIronPanel(
      currentTime >= SEVEN_MINUTES + 39 &&
        currentTime < SEVEN_MINUTES + 42 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutReflexNeedle(
      currentTime >= EIGHT_MINUTES + 11 &&
        currentTime < EIGHT_MINUTES + 14 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutReflexPerformCBCWithReflex(
      currentTime >= EIGHT_MINUTES + 11 &&
        currentTime < EIGHT_MINUTES + 14 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexNeedleLoop(
      currentTime >= EIGHT_MINUTES + 19 &&
        currentTime < EIGHT_MINUTES + 30 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexNeedleText(
      currentTime >= EIGHT_MINUTES + 19 &&
        currentTime < EIGHT_MINUTES + 30 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutReflexTestReport(
      currentTime >= EIGHT_MINUTES + 47 &&
        currentTime < EIGHT_MINUTES + 49 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutReflexMakeDiagnosis(
      currentTime >= EIGHT_MINUTES + 47 &&
        currentTime < EIGHT_MINUTES + 49 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutReflexGoBackScreen(
      currentTime >= EIGHT_MINUTES + 47 &&
        currentTime < EIGHT_MINUTES + 49 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCancer(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCKD(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisGiDisorders(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisHF(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCancerClose(
      currentTime >= NINE_MINUTES + 7 &&
        currentTime < NINE_MINUTES + 8 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCKDClose(
      currentTime >= NINE_MINUTES + 12 &&
        currentTime < NINE_MINUTES + 13 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisGiDisordersClose(
      currentTime >= NINE_MINUTES + 17 &&
        currentTime < NINE_MINUTES + 18 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisHFClose(
      currentTime >= NINE_MINUTES + 22 &&
        currentTime < NINE_MINUTES + 23 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCbcWithout(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCbcWith(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisCbcWithReflex(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithoutReflexIdaDiagnosisIronPanel(
      currentTime >= EIGHT_MINUTES + 59 &&
        currentTime < NINE_MINUTES + 2 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithReflexNeedle(
      currentTime >= NINE_MINUTES + 30 &&
        currentTime < NINE_MINUTES + 33 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexNeedleLoop(
      currentTime >= NINE_MINUTES + 43 &&
        currentTime < NINE_MINUTES + 54 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexNeedleText(
      currentTime >= NINE_MINUTES + 43 &&
        currentTime < NINE_MINUTES + 54 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithReflexTestReport(
      currentTime >= TEN_MINUTES + 10 &&
        currentTime < TEN_MINUTES + 12 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithReflexMakeDiagnosis(
      currentTime >= TEN_MINUTES + 10 &&
        currentTime < TEN_MINUTES + 12 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithReflexGoBackScreen(
      currentTime >= TEN_MINUTES + 10 &&
        currentTime < TEN_MINUTES + 12 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCancer(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCKD(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisGiDisorders(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisHF(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCancerClose(
      currentTime >= TEN_MINUTES + 33 &&
        currentTime < TEN_MINUTES + 34 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCKDClose(
      currentTime >= TEN_MINUTES + 39 &&
        currentTime < TEN_MINUTES + 40 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisGiDisordersClose(
      currentTime >= TEN_MINUTES + 43 &&
        currentTime < TEN_MINUTES + 44 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisHFClose(
      currentTime >= TEN_MINUTES + 48 &&
        currentTime < TEN_MINUTES + 49 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCbcWithout(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCbcWith(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisCbcWithoutReflex(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setcbcWithReflexIdaDiagnosisIronPanel(
      currentTime >= TEN_MINUTES + 24 &&
        currentTime < TEN_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelNeedle(
      currentTime >= TEN_MINUTES + 55 &&
        currentTime < TEN_MINUTES + 59 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelNeedleLoop(
      currentTime >= ELEVEN_MINUTES + 7 &&
        currentTime < ELEVEN_MINUTES + 18 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelNeedleText(
      currentTime >= ELEVEN_MINUTES + 7 &&
        currentTime < ELEVEN_MINUTES + 18 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelTestReport(
      currentTime >= ELEVEN_MINUTES + 28 &&
        currentTime < ELEVEN_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );

    setironPanelMakeDiagnosis(
      currentTime >= ELEVEN_MINUTES + 28 &&
        currentTime < ELEVEN_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelGoBackScreen(
      currentTime >= ELEVEN_MINUTES + 28 &&
        currentTime < ELEVEN_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelIdaDiagnosisCbcWithout(
      currentTime >= ELEVEN_MINUTES + 44 &&
        currentTime < ELEVEN_MINUTES + 47 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelIdaDiagnosisCbcWith(
      currentTime >= ELEVEN_MINUTES + 44 &&
        currentTime < ELEVEN_MINUTES + 47 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelIdaDiagnosisCbcWithoutReflex(
      currentTime >= ELEVEN_MINUTES + 44 &&
        currentTime < ELEVEN_MINUTES + 47 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelIdaDiagnosisWithReflex(
      currentTime >= ELEVEN_MINUTES + 44 &&
        currentTime < ELEVEN_MINUTES + 47 &&
        currentIndex === videoIndexs.ANEMIA
    );
    //REVIEW TEST REPORTS
    setCbcWithoutDiffReviewTestReportClose(
      currentTime >= ONE_MINUTE + 29 &&
        currentTime < ONE_MINUTE + 30 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffIronPanelTestReportClose(
      currentTime >= TWO_MINUTES + 11 &&
        currentTime < TWO_MINUTES + 12 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffAnemiaPanelTestReportClose(
      currentTime >= THREE_MINUTES &&
        currentTime < THREE_MINUTES + 1 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithoutDiffVitaminPanelTestReportClose(
      currentTime >= THREE_MINUTES + 47 &&
        currentTime < THREE_MINUTES + 48 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffIronPanelTestReportClose(
      currentTime >= SIX_MINUTES + 2 &&
        currentTime < SIX_MINUTES + 3 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffAnemiaPanelTestReportClose(
      currentTime >= SIX_MINUTES + 52 &&
        currentTime < SIX_MINUTES + 53 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setCbcWithDiffVitaminPanelTestReportClose(
      currentTime >= SEVEN_MINUTES + 35 &&
        currentTime < SEVEN_MINUTES + 36 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setironPanelTestReportClose(
      currentTime >= ELEVEN_MINUTES + 37 &&
        currentTime < ELEVEN_MINUTES + 38 &&
        currentIndex === videoIndexs.ANEMIA
    );

    //TIPPY HOVER STATES
    setHemoglobinConcHome(
      currentTime >= 34 && currentTime < 36 && currentIndex === videoIndexs.ANEMIA
    );
    sethematocritHome(currentTime >= 34 && currentTime < 36 && currentIndex === videoIndexs.ANEMIA);

    setrbcCountHome(currentTime >= 34 && currentTime < 36 && currentIndex === videoIndexs.ANEMIA);

    setplateletCountCbcWithout(
      currentTime >= 48 && currentTime < 50 && currentIndex === videoIndexs.ANEMIA
    );

    setwbccountCbcbWithout(
      currentTime >= 48 && currentTime < 50 && currentIndex === videoIndexs.ANEMIA
    );

    setferritinCbcWithoutIron(
      currentTime >= ONE_MINUTE + 37 &&
        currentTime < ONE_MINUTE + 39 &&
        currentIndex === videoIndexs.ANEMIA
    );
    settibcCbcWithoutIron(
      currentTime >= ONE_MINUTE + 37 &&
        currentTime < ONE_MINUTE + 39 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setserumIronCbcWithoutIron(
      currentTime >= ONE_MINUTE + 37 &&
        currentTime < ONE_MINUTE + 39 &&
        currentIndex === videoIndexs.ANEMIA
    );
    settsatCbcWithoutIron(
      currentTime >= ONE_MINUTE + 37 &&
        currentTime < ONE_MINUTE + 39 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setfunctionalIronCbcWithoutIron(
      currentTime >= ONE_MINUTE + 37 &&
        currentTime < ONE_MINUTE + 39 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setvitaminb12CbcWithoutAnemia(
      currentTime >= TWO_MINUTES + 19 &&
        currentTime < TWO_MINUTES + 21 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setfolateCbcWithoutAnemia(
      currentTime >= TWO_MINUTES + 19 &&
        currentTime < TWO_MINUTES + 21 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setretiCountCbcWithoutAnemia(
      currentTime >= TWO_MINUTES + 19 &&
        currentTime < TWO_MINUTES + 21 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setplateletCountCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setwbcCountCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setneutrophilsCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setlymphocytesCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setmonocytesCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    seteosinophilsCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setbasophilsCbcWith(
      currentTime >= FOUR_MINUTES + 29 &&
        currentTime < FOUR_MINUTES + 31 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setferritinCbcWithIron(
      currentTime >= FIVE_MINUTES + 25 &&
        currentTime < FIVE_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    settibcCbcWithIron(
      currentTime >= FIVE_MINUTES + 25 &&
        currentTime < FIVE_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setserumIronCbcWithIron(
      currentTime >= FIVE_MINUTES + 25 &&
        currentTime < FIVE_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    settsatCbcWithIron(
      currentTime >= FIVE_MINUTES + 25 &&
        currentTime < FIVE_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setfunctionalIronCbcWithIron(
      currentTime >= FIVE_MINUTES + 25 &&
        currentTime < FIVE_MINUTES + 27 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setTippyCbcWithAnemia(
      currentTime >= SIX_MINUTES + 9 &&
        currentTime < SIX_MINUTES + 11 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setTippyCbcWithoutReflex(
      currentTime >= EIGHT_MINUTES + 11 &&
        currentTime < EIGHT_MINUTES + 14 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setTippyCbcWithReflex(
      currentTime >= NINE_MINUTES + 31 &&
        currentTime < NINE_MINUTES + 33 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setTippyCbcWithReflexPanel(
      currentTime >= TEN_MINUTES + 10 &&
        currentTime < TEN_MINUTES + 12 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setTippyIronPanel(
      currentTime >= TEN_MINUTES + 56 &&
        currentTime < TEN_MINUTES + 58 &&
        currentIndex === videoIndexs.ANEMIA
    );
    setTippyCbcWithoutReflexPanel(
      currentTime >= EIGHT_MINUTES + 47 &&
        currentTime < EIGHT_MINUTES + 49 &&
        currentIndex === videoIndexs.ANEMIA
    );
  }, [currentTime, currentIndex]);

  return (
    <>
      {isHotSpot2Active && (
        <div
          id="bc_assess_stage"
          text="bc_assess_stage"
          onClick={() => setCurrentVideoTime(20.0)}
          style={{
            position: 'absolute',
            top: '69%',
            left: '57%',
            width: '18%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isHotSpot3Active && (
        <div
          id="bc_determine_her2_status"
          text="bc_determine_her2_status"
          onClick={() => setCurrentVideoTime(145.0)}
          style={{
            position: 'absolute',
            top: '69%',
            left: '76%',
            width: '18%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {iscTNMHotSpotActive && (
        <div
          id="bc_ctnm_select_option"
          text="bc_ctnm_select_option"
          onClick={() => setCurrentVideoTime(23.1)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '17%',
            width: '16%',
            height: '9%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {ispTNMHotSpotActive && (
        <div
          id="bc_ptnm_select_option"
          text="bc_ptnm_select_option"
          onClick={() => setCurrentVideoTime(26.0)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '34%',
            width: '16%',
            height: '9%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isAssessPrimaryHotSpotActive && (
        <div
          id="bc_to_begin_select_option"
          text="bc_to_begin_select_option"
          onClick={() => setCurrentVideoTime(28.0)}
          style={{
            position: 'absolute',
            top: '66%',
            left: '13%',
            width: '41%',
            height: '10%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isClosePopupActivecTNM && (
        <div
          onClick={() => setCurrentVideoTime(22.0)}
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isClosePopupActivepTNM && (
        <div
          onClick={() => setCurrentVideoTime(22.0)}
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isMeasureTumorHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(47.0)}
          style={{
            position: 'absolute',
            top: '39%',
            left: '56%',
            width: '9%',
            height: '14%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isMeasureTumorTwoHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(72.0)}
          style={{
            position: 'absolute',
            top: '39%',
            left: '82%',
            width: '9%',
            height: '14%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isReviewTCategoriesHotSpotActive && (
        <div
          id="bc_review_all_t_categories_and_criteria"
          text="bc_review_all_t_categories_and_criteria"
          onClick={() => setCurrentVideoTime(77.0)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '14%',
            width: '38%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isNextNodalAssessHotSpotActive && (
        <div
          id="bc_next_assess_nodal_involvement"
          text="bc_next_assess_nodal_involvement"
          onClick={() => setCurrentVideoTime(79.5)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '14%',
            width: '38%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isClosePopupActiveTumoranatomic && (
        <div
          onClick={() => setCurrentVideoTime(76.0)}
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isNAssesRegionalHotSpotActive && (
        <div
          id="bc_select_highlighted_nodes_graphic_click"
          text="bc_select_highlighted_nodes_graphic_click"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 38.5)}
          style={{
            position: 'absolute',
            top: '10%',
            left: '16%',
            width: '12%',
            height: '19%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isNAssesRegionalHotSpotActive && (
        <div
          id="bc_select_highlighted_nodes_text_click"
          text="bc_select_highlighted_nodes_text_click"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 38.5)}
          style={{
            position: 'absolute',
            top: '56%',
            left: '62%',
            width: '18%',
            height: '6%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isReviewAllCNHotSpotActive && (
        <div
          id="bc_review_all_cn_categories_and_criteria"
          text="bc_review_all_cn_categories_and_criteria"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 43)}
          style={{
            position: 'absolute',
            top: '48%',
            left: '63%',
            width: '30%',
            height: '10%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isFinallyAssesSpotActive && (
        <div
          id="bc_finally_assess_metastates_select_option"
          text="bc_finally_assess_metastates_select_option"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 45.5)}
          style={{
            position: 'absolute',
            top: '62%',
            left: '63%',
            width: '30%',
            height: '10%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isClinicalAnatomicPopUpActive && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 42.0)}
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isImagingHotSpotActive && (
        <div
          id="bc_imaging_select_option"
          text="bc_imaging_select_option"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 6.0)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '18%',
            width: '16%',
            height: '10%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isBodyScanStartHotSpotActive && (
        <div
          id="bc_scan_body_graphic_click"
          text="bc_scan_body_graphic_click"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 11.0)}
          style={{
            position: 'absolute',
            top: '28%',
            left: '64%',
            width: '20%',
            height: '30%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isBodyScanStartHotSpotActive && (
        <div
          id="bc_scan_body_click_text"
          text="bc_scan_body_click_text"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 11.0)}
          style={{
            position: 'absolute',
            top: '63%',
            left: '7%',
            width: '10%',
            height: '6%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isRadiographicPopUpActive && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 48.0)}
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isReviewAllMHotSpotActive && (
        <div
          id="bc_review_all_m_categories_and_criteria"
          text="bc_review_all_m_categories_and_criteria"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 20.0)}
          style={{
            position: 'absolute',
            top: '45%',
            left: '12%',
            width: '30%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isCalculateStageHotSpotActive && (
        <div
          id="bc_calculate_stage_based_on_tnm_score"
          text="bc_calculate_stage_based_on_tnm_score"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 22.5)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '12%',
            width: '30%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isDistantMetastasesPopUpActive && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 19.0)}
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isDetermineHer2HotSpotActive && (
        <div
          id="bc_determine_her2_status_select_option"
          text="bc_determine_her2_status_select_option"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 25.0)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '11%',
            width: '34%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isTumorBiopsyHotSpotActive && (
        <div
          id="bc_tumor_biopsy_select_option"
          text="bc_tumor_biopsy_select_option"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 26.5)}
          style={{
            position: 'absolute',
            top: '70%',
            left: '32%',
            width: '18%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isLiquidBiopsyHotSpotActive && (
        <div
          id="bc_liquid_biopsy_select_option"
          text="bc_liquid_biopsy_select_option"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 1.0)}
          style={{
            position: 'absolute',
            top: '70%',
            left: '51%',
            width: '18%',
            height: '11%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isTwentyTwoGuageHotSpotActive && (
        <div
          id="bc_22_guage_needle_option"
          text="bc_22_guage_needle_option"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 36.0)}
          style={{
            position: 'absolute',
            top: '40%',
            left: '30%',
            width: '12%',
            height: '42%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isFourteenGuageHotSpotActive && (
        <div
          id="bc_14_guage_needle_option"
          text="bc_14_guage_needle_option"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 2.0)}
          style={{
            position: 'absolute',
            top: '40%',
            left: '44%',
            width: '12%',
            height: '42%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isScalpelHotSpotActive && (
        <div
          id="bc_scalpel_option"
          text="bc_scalpel_option"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 22.5)}
          style={{
            position: 'absolute',
            top: '40%',
            left: '58%',
            width: '12%',
            height: '42%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isTumorBiopsyNeedleHotSpotActive && (
        <div
          id="bc_click_needle_to_take_sample_graphic_click"
          text="bc_click_needle_to_take_sample_graphic_click"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 56.0)}
          style={{
            position: 'absolute',
            top: '24%',
            left: '22%',
            width: '24%',
            height: '42%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isTumorBiopsyNeedleHotSpotActive && (
        <div
          id="bc_click_needle_to_take_sample_text_click"
          text="bc_click_needle_to_take_sample_text_click"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 56.0)}
          style={{
            position: 'absolute',
            top: '55%',
            left: '54%',
            width: '21%',
            height: '6%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isFourteenGuageTumorHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 18.0)}
          style={{
            position: 'absolute',
            top: '24%',
            left: '24%',
            width: '24%',
            height: '42%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isFourteenGuageTumorHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 18.0)}
          style={{
            position: 'absolute',
            top: '55%',
            left: '54%',
            width: '21%',
            height: '6%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isScalpelTumorHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 39.0)}
          style={{
            position: 'absolute',
            top: '48%',
            left: '11%',
            width: '18%',
            height: '32%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isScalpelTumorHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 39.0)}
          style={{
            position: 'absolute',
            top: '55%',
            left: '54%',
            width: '21%',
            height: '6%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isLiquidBiopsyNGSHotSpotActive && (
        <div
          id="bc_click_the_needle_to_collect_sample_graphic_click"
          text="bc_click_the_needle_to_collect_sample_graphic_click"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 9.0)}
          style={{
            position: 'absolute',
            top: '49%',
            left: '44%',
            width: '12%',
            height: '32%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isLiquidBiopsySampleHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 27.0)}
          style={{
            position: 'absolute',
            top: '33%',
            left: '56%',
            width: '23%',
            height: '42%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isLiquidBiopsySampleHotSpotActive && (
        <div
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 27.0)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '8%',
            width: '23%',
            height: '6%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isIHCTestingHotSpotActive && (
        <div
          id="bc_ihc_select_option"
          text="bc_ihc_select_option"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 49.0)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '18%',
            width: '21%',
            height: '12%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(1 , 10, 290, 0.8)'
          }}
        />
      )}
      {isISHTestingHotSpotActive && (
        <div
          id="bc_ish_select_option"
          text="bc_ish_select_option"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 28.0)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '40%',
            width: '21%',
            height: '12%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 10, 290, 0.8)'
          }}
        />
      )}
      {isNGSTestingHotSpotActive && (
        <div
          id="bc_ngs_select_option"
          text="bc_ngs_select_option"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 44)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '62%',
            width: '21%',
            height: '12%',
            cursor: 'pointer'
            // backgroundColor: 'rgba(100, 35, 10, 0.8)'
          }}
        />
      )}
      {LungCancerIntro && (
        <div
          id="HP_Lung_Cancer_Bubble_Select"
          text="Lung_Cancer_Graphic_Nav"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 1.0)}
          style={{
            position: 'absolute',
            top: '38%',
            left: '31%',
            width: '8%',
            height: '12%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(7 255 0 / 71%)'
          }}
        />
      )}
      {LungCancerIntro && (
        <div
          id="HP_Lung_Cancer_Select"
          text="Lung_Cancer_Text_Nav"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 1.0)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '53%',
            width: '12%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
          }}
        />
      )}
      {breastCancerIntro && (
        <div
          id="HP_Breast_Cancer_Bubble_Select"
          text="Breast_Cancer_Graphic_Nav"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 40.0)}
          style={{
            position: 'absolute',
            top: '53%',
            left: '23%',
            width: '8%',
            height: '12%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(7 255 0 / 71%)'
          }}
        />
      )}
      {breastCancerIntro && (
        <div
          id="HP_Breast_Cancer_Select"
          text="Breast_Cancer_Text_Nav"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 40.0)}
          style={{
            position: 'absolute',
            top: '74%',
            left: '53%',
            width: '10%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(7 10 0 / 71%)'
          }}
        />
      )}
      {anemiaIntro && (
        <div
          id="HP_Anemia_Bubble_Select"
          text="Anemia_Graphic_Nav"
          onClick={() => setCurrentVideoTime(26.0)}
          style={{
            position: 'absolute',
            top: '62%',
            left: '7%',
            width: '8%',
            height: '12%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(7 255 0 / 71%)'
          }}
        />
      )}
      {anemiaIntro && (
        <div
          id="HP_Anemia_Select"
          text="Anemia_Text_Nav"
          onClick={() => setCurrentVideoTime(26.0)}
          style={{
            position: 'absolute',
            top: '83%',
            left: '52%',
            width: '10%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(7 255 0 / 71%)'
          }}
        />
      )}
      {exploreComponents && (
        <div
          id="Explore_Components_Select_Action"
          text="Explore_Components_of_Diagnosis"
          onClick={() => setCurrentVideoTime(33.0)}
          style={{
            position: 'absolute',
            top: '48%',
            right: '7%',
            width: '36%',
            height: '12%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(7 255 0 / 71%)'
          }}
        />
      )}
      {assesStage && (
        <div
          id="Assess_Stage_Select_Action"
          text="Assess_Stage"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 46.0)}
          style={{
            position: 'absolute',
            top: '61%',
            right: '7%',
            width: '36%',
            height: '12%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(7 255 340 / 71%)'
          }}
        />
      )}
      {testBiomarkers && (
        <div
          id="Test_Biomarkers_Select_Action"
          text="Test_for_Biomarkers"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 2.5)}
          style={{
            position: 'absolute',
            top: '74%',
            right: '7%',
            width: '36%',
            height: '12%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {componentsImaging && (
        <div
          id="Imaging_Select_LM"
          text="Imaging_Select"
          onClick={() => setCurrentVideoTime(41.0)}
          style={{
            position: 'absolute',
            top: '61%',
            left: '23%',
            width: '17%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {componentsBiopsy && (
        <div
          id="Biopsy_Select_LM"
          text="Biopsy_Select"
          onClick={() => setCurrentVideoTime(56.4)}
          style={{
            position: 'absolute',
            top: '61%',
            left: '42%',
            width: '17%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {componentsHistological && (
        <div
          id="Histologcal_Assessment_LM"
          text="Histologcal_Assessment_Select"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 13.0)}
          style={{
            position: 'absolute',
            top: '61%',
            left: '60%',
            width: '17%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {componentsBackToLung && (
        <div
          id="Back_to_Lung_Cancer_HP"
          text="Back_to_Lung_Cancer_HP"
          onClick={() => setCurrentVideoTime(29.0)}
          style={{
            position: 'absolute',
            top: '76%',
            left: '23%',
            width: '54%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingChest && (
        <div
          id="Chest_X_Ray_LM"
          text="Chest_X_Ray_LM"
          onClick={() => {
            setCurrentVideoTime(54.0);
            navigate('/lungwall/8/0/0');
          }}
          style={{
            position: 'absolute',
            top: '43%',
            left: '22%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingCT && (
        <div
          id="CT_LM"
          text="CT_LM"
          onClick={() => {
            setCurrentVideoTime(54.0);
            navigate('/lungwall/8/0/1');
          }}
          style={{
            position: 'absolute',
            top: '43%',
            left: '33%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingPET && (
        <div
          id="PET_CT_LM"
          text="PET_CT_LM"
          onClick={() => {
            setCurrentVideoTime(54.0);
            navigate('/lungwall/8/0/2');
          }}
          style={{
            position: 'absolute',
            top: '43%',
            left: '45%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingMRI && (
        <div
          id="MRI_LM"
          text="MRI_LM"
          onClick={() => {
            setCurrentVideoTime(54.0);
            navigate('/lungwall/8/0/3');
          }}
          style={{
            position: 'absolute',
            top: '43%',
            left: '56%',
            width: '11%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingBone && (
        <div
          id="Bone_Scintigraphy_LM"
          text="Bone_Scintigraphy_LM"
          onClick={() => {
            setCurrentVideoTime(54.0);
            navigate('/lungwall/8/0/4');
          }}
          style={{
            position: 'absolute',
            top: '43%',
            left: '68%',
            width: '11%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingBiospy && (
        <div
          id="Biopsy_Select_LM_DI"
          text="Biopsy_Select_DI"
          onClick={() => setCurrentVideoTime(56.0)}
          style={{
            position: 'absolute',
            top: '63%',
            left: '21%',
            width: '28%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingHistological && (
        <div
          id="Histologcal_Assessment_LM_DI"
          text="Histologcal_Assessment_Select_DI"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 13.0)}
          style={{
            position: 'absolute',
            top: '63%',
            left: '51%',
            width: '28%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {imagingBacktoLung && (
        <div
          id="Back_to_Lung_Cancer_HP_DI"
          text="Back_to_Lung_Cancer_HP_DI"
          onClick={() => setCurrentVideoTime(29.0)}
          style={{
            position: 'absolute',
            top: '76%',
            left: '22%',
            width: '56%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {/* ? */}
      {biopsyMediastinoscopy && (
        <div
          id="Mediastinoscopy_TB"
          text="Mediastinoscopy_TB"
          onClick={() => {
            setCurrentVideoTime(ONE_MINUTE + 9);
            navigate('/lungwall/7/0/0');
            // setOverlayOpen(true);
          }}
          style={{
            position: 'absolute',
            top: '45%',
            left: '23%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biospyThorascopy && (
        <div
          id="Thoracoscpy_TB"
          text="Thoracoscpy_TB"
          onClick={() => {
            setCurrentVideoTime(ONE_MINUTE + 9);
            navigate('/lungwall/7/0/1');
            // setOverlayOpen(true);
          }}
          style={{
            position: 'absolute',
            top: '45%',
            left: '34%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biopsyEbus && (
        <div
          id="EBUS_TB"
          text="EBUS_TB"
          onClick={() => {
            setCurrentVideoTime(ONE_MINUTE + 9);
            navigate('/lungwall/7/0/2');
            // setOverlayOpen(true);
          }}
          style={{
            position: 'absolute',
            top: '45%',
            left: '45%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biopsyEus && (
        <div
          id="EUS_TB"
          text="EUS_TB"
          onClick={() => {
            setCurrentVideoTime(ONE_MINUTE + 9);
            navigate('/lungwall/7/0/3');
            // setOverlayOpen(true);
          }}
          style={{
            position: 'absolute',
            top: '45%',
            left: '56%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biopsyCT && (
        <div
          id="CT_Guided_TB"
          text="CT_Guided_TB"
          onClick={() => {
            setCurrentVideoTime(ONE_MINUTE + 9);
            navigate('/lungwall/7/0/4');
            // setOverlayOpen(true);
          }}
          style={{
            position: 'absolute',
            top: '45%',
            left: '67%',
            width: '10%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biopsyImaging && (
        <div
          id="Imaging_Select_LM_TB"
          text="Imaging_Select_TB"
          onClick={() => setCurrentVideoTime(41.0)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '23%',
            width: '26%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biopsyHistological && (
        <div
          id="Histologcal_Assessment_LM_TB"
          text="Histologcal_Assessment_Select_TB"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 13.0)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '51%',
            width: '26%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biopsyBackToLung && (
        <div
          id="Back_to_Lung_Cancer_HP_TB"
          text="Back_to_Lung_Cancer_HP_TB"
          onClick={() => setCurrentVideoTime(29.0)}
          style={{
            position: 'absolute',
            top: '78%',
            left: '23%',
            width: '55%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {/*  */}
      {histologicalAdeno && (
        <div
          id="Adenocarcinoma_Data_Graph_ Expand"
          text="Adenocarcinoma_Data_Graph_ Expand"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 21.0)}
          style={{
            position: 'absolute',
            top: '44%',
            left: '48%',
            width: '36%',
            height: '5%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalSquamous && (
        <div
          id="SCC_Graph_Expand"
          text="SCC_Graph_Expand"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 38.0)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '48%',
            width: '16%',
            height: '5%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalLarge && (
        <div
          id="LCC_Graph_Expand"
          text="LCC_Graph_Expand"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 31.0)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '48%',
            width: '6%',
            height: '5%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalAdenoClose && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 19.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalSquamousClose && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 19.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalLargeClose && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 19.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalImaging && (
        <div
          id="Imaging_Select_LM_HA"
          text="Imaging_Select_HA"
          onClick={() => setCurrentVideoTime(41.0)}
          style={{
            position: 'absolute',
            top: '75%',
            left: '23%',
            width: '27%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalBiopsy && (
        <div
          id="Histologcal_Assessment_LM_TB"
          text="Histologcal_Assessment_Select_TB"
          onClick={() => setCurrentVideoTime(56.4)}
          style={{
            position: 'absolute',
            top: '75%',
            left: '51%',
            width: '26%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {histologicalBackToLung && (
        <div
          id="Back_to_Lung_Cancer_HP_TB"
          text="Back_to_Lung_Cancer_HP_TB"
          onClick={() => setCurrentVideoTime(29.0)}
          style={{
            position: 'absolute',
            top: '84%',
            left: '24%',
            width: '53%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagectnm && (
        <div
          id="cTNM_Select_Option"
          text="cTNM_Select"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 52.0)}
          style={{
            position: 'absolute',
            top: '57%',
            left: '56%',
            width: '18%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStageptnm && (
        <div
          id="pTNM_Select_Option"
          text="pTNM_Select"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 54.7)}
          style={{
            position: 'absolute',
            top: '57%',
            left: '75%',
            width: '18%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assesStageBegin && (
        <div
          id="To_Begin_Assess_Primary_Tumor_Select_Option"
          text="To_Begin_Assess_Primary_Tumor_Select"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 56.7)}
          style={{
            position: 'absolute',
            top: '69%',
            left: '57%',
            width: '36%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagectnmClose && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 50.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStageptnmClose && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 50.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumor && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 16.0)}
          style={{
            position: 'absolute',
            top: '44%',
            left: '57%',
            width: '8%',
            height: '13%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumor && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 16.0)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '13%',
            width: '32%',
            height: '5%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 10 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumor2 && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 34.0)}
          style={{
            position: 'absolute',
            top: '44%',
            left: '80%',
            width: '8%',
            height: '13%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumor2 && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 34.0)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '13%',
            width: '32%',
            height: '5%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 10 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumorReview && (
        <div
          id="Review_All_T_Categories_Select_Option_TAPT"
          text="Review_All_T_Categories_TAPT"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 42.0)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '13%',
            width: '38%',
            height: '9%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumorReviewClose && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 38.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStagePrimaryTumorNext && (
        <div
          id="Next_Assess_Nodal_Involvement_Select_Option"
          text="Next_Assess_Nodal_Involvement"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 46.0)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '13%',
            width: '38%',
            height: '9%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStageNRegional && (
        <div
          id="Select_Highlighted_Nodes_Graphic_Bubble "
          text="Select_Highlighted_Nodes_Graphic_Bubble "
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 5.0)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '17%',
            width: '7%',
            height: '13%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStageNRegional && (
        <div
          id="Select_Highlighted_Nodes_Text "
          text="Select_Highlighted_Nodes_Text "
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 5.0)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '48%',
            width: '19%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 10 0 / 71%)'
          }}
        />
      )}
      {assessStageNReviewAll && (
        <div
          id="Review_All_T_Categories_Select_Option_NANI"
          text="Review_All_T_Categories_NANI"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 14.0)}
          style={{
            position: 'absolute',
            top: '35%',
            left: '48%',
            width: '45%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStageNReivewAllClose && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 12.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessStageFinally && (
        <div
          id="Finally_Assess_Metastases_Select_Option"
          text="Finally_Assess_Metastases_Select_Option"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 17.0)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '48%',
            width: '45%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessMetaStart && (
        <div
          id="Scan_Body_Text_Click"
          text="Scan_Body_Text_Click"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 41.0)}
          style={{
            position: 'absolute',
            top: '57%',
            left: '6%',
            width: '12%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessMetaStart && (
        <div
          id="Scan_Body_Graphic_Click"
          text="Scan_Body_Graphic_Click"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 41.0)}
          style={{
            position: 'absolute',
            top: '28%',
            left: '64%',
            width: '20%',
            height: '30%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessMetaReview && (
        <div
          id="Review_All_T_Categories_Select_Option_AM"
          text="Review_All_T_Categories_AM"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 56.0)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '12%',
            width: '30%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessMetaCalculate && (
        <div
          id="Calculate_Stage_TNM"
          text="Calculate_Stage_TNM"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 58.0)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '12%',
            width: '30%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessMetaReviewClose && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 54.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {assessMetaTest && (
        <div
          id="Test_for_Biomarkers_TNM"
          text="Test_for_Biomarkers_TNM"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 1)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '11%',
            width: '33%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biomarkerLearnNSCLC && (
        <div
          id="Learn_NSCLC_Select_Option"
          text="Learn_NSCLC_Select_Option"
          onClick={() => {
            setCurrentVideoTime(FOUR_MINUTES + 8);
          }}
          style={{
            position: 'absolute',
            top: '63%',
            left: '16%',
            width: '33%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biomarkerCollectSample && (
        <div
          id="Collect_Sample_Testing_Select_Option"
          text="Collect_Sample_Testing_Select_Option"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 13)}
          style={{
            position: 'absolute',
            top: '63%',
            left: '51%',
            width: '33%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biomarkerTumorBiopsy && (
        <div
          id="Tumor_Biopsy_Select_SC"
          text="Tumor_Biopsy_Select_SC"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 18)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '22%',
            width: '27%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {biomarkerLiquidBiopsy && (
        <div
          id="Liquid_Biopsy_Select_SC"
          text="Liquid_Biopsy_Select_SC"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 23)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '51%',
            width: '27%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsy22Gauge && (
        <div
          id="22_Gauge_Select_Option_TB"
          text="22_Gauge_Select_Option_TB"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 23)}
          style={{
            position: 'absolute',
            top: '42%',
            left: '23%',
            width: '17%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsyEndoscope && (
        <div
          id="Endoscope_Select_Option_TB"
          text="Endoscope_Select_Option_TB"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 48)}
          style={{
            position: 'absolute',
            top: '42%',
            left: '42%',
            width: '17%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsyMediastionscope && (
        <div
          id="Mediastinoscope_Select_Option_TB"
          text="Mediastinoscope_Select_Option_TB"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 17)}
          style={{
            position: 'absolute',
            top: '42%',
            left: '60%',
            width: '17%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsy22GaugeTakeSample && (
        <div
          id="Click_Needle_Sample_Graphic_Bubble_Click"
          text="Click_Needle_Sample_Graphic_Bubble_Click"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 41)}
          style={{
            position: 'absolute',
            top: '22%',
            left: '33%',
            width: '19%',
            height: '30%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsy22GaugeTakeSample && (
        <div
          id="Click_Needle_Sample_Text_Click"
          text="Click_Needle_Sample_Text_Click"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 41)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '60%',
            width: '20%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 15 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsyEndoscopeTakeSample && (
        <div
          id="Click_Endoscope_Graphic_Bubble_Click"
          text="Click_Endoscope_Graphic_Bubble_Click"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 6)}
          style={{
            position: 'absolute',
            top: '22%',
            left: '39%',
            width: '19%',
            height: '30%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsyEndoscopeTakeSample && (
        <div
          id="Click_Endoscope_Text_Click"
          text="Click_Endoscope_Text_Click"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 6)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '60%',
            width: '23%',
            height: '7%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 10 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsyMediaTakeSample && (
        <div
          id="Click_Mediastinoscope_Graphic_Bubble_Click"
          text="Click_Mediastinoscope_Graphic_Bubble_Click"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 35)}
          style={{
            position: 'absolute',
            top: '28%',
            left: '39%',
            width: '17%',
            height: '30%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {tumorBiopsyMediaTakeSample && (
        <div
          id="Click_Mediastinoscope_Text_Click"
          text="Click_Mediastinoscope_Text_Click"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 35)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '58%',
            width: '26%',
            height: '7%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 10 0 / 71%)'
          }}
        />
      )}
      {liquidBiopsyNeedle && (
        <div
          id="Click_Needle_Sample_LB"
          text="Click_Needle_Sample_LB"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 31)}
          style={{
            position: 'absolute',
            top: '50%',
            left: '44%',
            width: '12%',
            height: '32%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {liquidBiopsyNeedleTakeSample && (
        <div
          id="Click_Needle_Sample_LB_2"
          text="Click_Needle_Sample_LB_2"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 48)}
          style={{
            position: 'absolute',
            top: '29%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {liquidBiopsyNeedleTakeSample && (
        <div
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 48)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '8%',
            width: '23%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            // backgroundColor: 'rgb(290 10 0 / 71%)'
          }}
        />
      )}
      {screenForIDA && (
        <div
          id="Screen_For_IDA_Select"
          text="Screen_for_IDA"
          onClick={() => setCurrentVideoTime(38.0)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '45%',
            width: '36%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {screeningCBCWithout && (
        <div
          id="CBC_Without_Differential"
          text="CBC_Without_Differential"
          onClick={() => setCurrentVideoTime(45.0)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '9%',
            width: '11%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {screeningCBCWith && (
        <div
          id="CBC_With_Differential_Select"
          text="CBC_With_Differential_Select"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 26.0)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '21%',
            width: '11%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {screeningCBCWithoutReflex && (
        <div
          id="CBC_Without_Differential_Reflex"
          text="CBC_Without_Differential_Reflex"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 13)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '33%',
            width: '23%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {screeningCBCWithReflex && (
        <div
          id="CBC_With_Differential_Reflex_to_Anemia"
          text="CBC_With_Differential_Reflex_to_Anemia"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 32)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '57%',
            width: '23%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {screeningIronPanel && (
        <div
          id="Iron_Panel_Select"
          text="Iron_Panel"
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 57)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '81%',
            width: '11%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffNeedle && (
        <div
          id="CBC_Without_Differential_Click_Needle_Sample"
          text="CBC_Without_Differential_Click_Needle_Sample"
          onClick={() => setCurrentVideoTime(55.0)}
          style={{
            position: 'absolute',
            top: '50%',
            left: '45%',
            width: '10%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {performCBCWithDiff && (
        <div
          id="Perform_CBC_With_Differential"
          text="Perform_CBC_With_Differential"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 27)}
          style={{
            position: 'absolute',
            top: '80%',
            left: '65%',
            width: '26%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(0 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffNeedleLoop && (
        <div
          id="Click_Needle_Take_Sample_Graphic_CTA_CBCWOD"
          text="Click_Needle_Take_Sample_Graphic_CTA_CBCWOD"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 11.0)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffNeedeLoopText && (
        <div
          id="Click_Needle_Take_Sample_Text_CTA"
          text="Click_Needle_Take_Sample_Text_CTA"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 11.0)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '8%',
            width: '26%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffReviewTestReport && (
        <div
          id="Review_Test_Report_CBCWOD"
          text="Review_Test_Report_CBCWOD"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 29.0)}
          style={{
            position: 'absolute',
            top: '20%',
            left: '24%',
            width: '52%',
            height: '17%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(10 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffReviewTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 24.0)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(10 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanel && (
        <div
          id="Iron_Panel_Select_CBCWOD"
          text="Iron_Panel_Select_CBCWOD"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 35.5)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '24%',
            width: '13%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaPanel && (
        <div
          id="Anemia_Panel_CBCWOD"
          text="Anemia_Panel_CBCWOD"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 17.0)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '38%',
            width: '24%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminDefTest && (
        <div
          id="Vitamin_Deficiency_Test_CBCWOD"
          text="Vitamin_Deficiency_Test_CBCWOD"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 7.0)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '63%',
            width: '13%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelTakeSample && (
        <div
          id="Click_Needle_Sample_Iron_Panel"
          text="Click_Needle_Sample_Iron_Panel"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 40)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '45%',
            width: '10%',
            height: '26%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelNeedle && (
        <div
          id="Click_Needle_Sample_Graphic_Iron_Panel_Page_2"
          text="Click_Needle_Sample_Graphic_Iron_Panel_Page_2"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 56)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '24%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelNeedleText && (
        <div
          id="Click_Needle_Sample_Text_Iron_Panel_Page_2"
          text="Click_Needle_Sample_Text_Iron_Panel_Page_2"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 56)}
          style={{
            position: 'absolute',
            top: '48%',
            left: '8%',
            width: '24%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelTestReport && (
        <div
          id="Review_Test_Report_Iron_Panel"
          text="Review_Test_Report_Iron_Panel"
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 11)}
          style={{
            position: 'absolute',
            top: '25%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(10 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 6)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(10 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelMakeDiagnosis && (
        <div
          id="Make_Diganosis_Iron_Panel"
          text="Make_Diganosis_Iron_Panel"
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 55.0)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelGoBackSelect && (
        <div
          id="Go_Back_Different_Test_Iron_Panel"
          text="Go_Back_Different_Test_Iron_Panel"
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 24.0)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffIronPanelGoBackScreen && (
        <div
          id="Go_Back_Screen_Another_Patient_Iron_Panel"
          text="Go_Back_Screen_Another_Patient_Iron_Panel"
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaNeedle && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 23)}
          style={{
            position: 'absolute',
            top: '61%',
            left: '43%',
            width: '11%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaNeedleLoop && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 38)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaNeedleText && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 38)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaPanelTestReport && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES)}
          style={{
            position: 'absolute',
            top: '25%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(TWO_MINUTES + 54)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaPanelMakeDiagnosis && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 55.0)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaPanelGoBackSelect && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 24.0)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffAnemiaPanelGoBackScreen && (
        <div
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminNeedle && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 15)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '45%',
            width: '10%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminNeedleLoop && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminNeedleText && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminPanelTestReport && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 47)}
          style={{
            position: 'absolute',
            top: '25%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 43)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminPanelMakeDiagnosis && (
        <div
          onClick={() => setCurrentVideoTime(THREE_MINUTES + 55.0)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminPanelGoBackSelect && (
        <div
          onClick={() => setCurrentVideoTime(ONE_MINUTE + 24.0)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutDiffVitaminPanelGoBackScreen && (
        <div
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCancer && (
        <div
          id="Cancer_Select_IDA_Diagnosis"
          text="Cancer_Select_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 5)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '14%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCKD && (
        <div
          id="CKD_Select_IDA_Diagnosis"
          text="CKD_Select_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 10)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '33%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisGiDisorders && (
        <div
          id="GI_Disorders_Select_IDA_Diagnosis"
          text="GI_Disorders_Select_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 18)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '51%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisHF && (
        <div
          id="HF_Select_IDA_Diagnosis"
          text="HF_Select_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 23)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '69%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCancerClose && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCKDClose && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisGiDisordersClose && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisHFClose && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCbcWith && (
        <div
          id="CBC_With_Differential_Select_IDA_Diagnosis"
          text="CBC_With_Differential_Select_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 27.0)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '15%',
            width: '11%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCbcWithoutReflex && (
        <div
          id="CBC_Without_Differential_Reflex_IDA_Diagnosis"
          text="CBC_Without_Differential_Reflex_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 10)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '27%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisCbcWithReflex && (
        <div
          id="CBC_With_Differential_Reflex_to_Anemia_IDA_Diagnosis"
          text="CBC_With_Differential_Reflex_to_Anemia_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '51%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {idaDiagnosisIronPanel && (
        <div
          id="Iron_Panel_Select_IDA_Diagnosis"
          text="Iron_Panel_IDA_Diagnosis"
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '75%',
            width: '11%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffNeedle && (
        <div
          id="Click_Needle_Sample_CBCWDiff"
          text="Click_Needle_Sample_CBCWDiff"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 35)}
          style={{
            position: 'absolute',
            top: '58%',
            left: '45%',
            width: '10%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffNeedleLoop && (
        <div
          id="Click_Needle_Sample_Graphic_CBCWDiff_Page_2"
          text="Click_Needle_Sample_Graphic_CBCWDiff_Page_2"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 52)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffNeedleText && (
        <div
          id="Click_Needle_Sample_Text_CBCWDiff_Page_2"
          text="Click_Needle_Sample_Text_CBCWDiff_Page_2"
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 52)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffTestReport && (
        <>
          <div
            id="Review_Test_Report_CBCWDiff"
            text="Review_Test_Report_CBCWDiff"
            onClick={() => {
              setcbcWithModalOpen(true);
            }}
            style={{
              position: 'absolute',
              top: '20%',
              left: '24%',
              width: '52%',
              height: '17%',
              cursor: 'pointer',
              zIndex: 2
              // backgroundColor: 'rgb(10 255 0 / 71%)'
            }}
          />
          <Modal
            isOpen={cbcWithModalOpen}
            className="modal-anemia bigger-modal higher-modal"
            overlayClassName="modal-overlay-anemia"
            shouldFocusAfterRender={false}
            onRequestClose={() => setcbcWithModalOpen(false)}
            parentSelector={() => document.getElementById('wirewax-container')}
            ariaHideApp={false}
          >
            <div className="anemia-modal-container">
              <div className="anemia-modal-title">SAMPLE TEST REPORT: CBC WITH DIFFERENTIAL</div>
              <div className="anemia-modal-inner-container">
                <img src={cbcWithDiffImage} alt="" className="modal-image" />
              </div>
              <img
                src={closeButton}
                className="modal-close-x"
                onClick={() => setcbcWithModalOpen(false)}
                alt=""
              />
            </div>
          </Modal>
        </>
      )}
      {cbcWithDiffIronPanel && (
        <div
          id="Iron_Panel_Select_CBCWDiff"
          text="Iron_Panel_Select_CBCWDiff"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 23)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '24%',
            width: '13%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaPanel && (
        <div
          id="Anemia_Panel_CBCWDiff"
          text="Anemia_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 8.5)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '38%',
            width: '24%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminDefTest && (
        <div
          id="Vitamin_Deficiency_Test_CBCWDiff"
          text="Vitamin_Deficiency_Test_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 58.0)}
          style={{
            position: 'absolute',
            top: '65%',
            left: '63%',
            width: '13%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelTakeSample && (
        <div
          id="Click_Needle_Sample_Iron_Panel_CBCWDiff"
          text="Click_Needle_Sample_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 28)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '45%',
            width: '10%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelNeedle && (
        <div
          id="Click_Needle_Sample_Graphic_Iron_Panel_CBCWDiff"
          text="Click_Needle_Sample_Graphic_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 45.0)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelNeedleText && (
        <div
          id="Click_Needle_Sample_Text_Iron_Panel_CBCWDiff"
          text="Click_Needle_Sample_Text_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 45.0)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelTestReport && (
        <div
          id="Review_Test_Report_Iron_Panel_CBCWDiff"
          text="Review_Test_Report_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 2)}
          style={{
            position: 'absolute',
            top: '25%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelMakeDiagnosis && (
        <div
          id="Make_Diganosis_Iron_Panel_CBCWDiff"
          text="Make_Diganosis_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 39)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelGoBackSelect && (
        <div
          id="Go_Back_Different_Test_Iron_Panel_CBCWDiff"
          text="Go_Back_Different_Test_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 3)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffIronPanelGoBackScreen && (
        <div
          id="Go_Back_Screen_Another_Patient_Iron_Panel_CBCWDiff"
          text="Go_Back_Screen_Another_Patient_Iron_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaNeedle && (
        <div
          id="Click_Needle_Sample_Anemia_Panel_CBCWDiff"
          text="Click_Needle_Sample_Anemia_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 13)}
          style={{
            position: 'absolute',
            top: '61%',
            left: '43%',
            width: '11%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaNeedleLoop && (
        <div
          id="Click_Needle_Sample_Anemia_Panel_Graphic_CBCWDiff"
          text="Click_Needle_Sample_Anemia_Panel_Graphic_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaNeedleText && (
        <div
          id="Click_Needle_Sample_Anemia_Panel_Text_CBCWDiff"
          text="Click_Needle_Sample_Anemia_Panel_Text_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaPanelTestReport && (
        <div
          id="Review_Test_Report_Anemia_Panel_CBCWDiff"
          text="Review_Test_Report_Anemia_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 52)}
          style={{
            position: 'absolute',
            top: '25%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(SIX_MINUTES + 47.5)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaPanelMakeDiagnosis && (
        <div
          id="Make_Diganosis_Anemia_Panel_CBCWDiff"
          text="Make_Diganosis_Anemia_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 39)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaPanelGoBackSelect && (
        <div
          id="Go_Back_Different_Test_Anemia_Panel_CBCWDiff"
          text="Go_Back_Different_Test_Anemia_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 3)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffAnemiaPanelGoBackScreen && (
        <div
          id="Go_Back_Screen_Another_Patient_Anemia_Panel_CBCWDiff"
          text="Go_Back_Screen_Another_Patient_Anemia_Panel_CBCWDiff"
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminNeedle && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 3)}
          style={{
            position: 'absolute',
            top: '54%',
            left: '45%',
            width: '10%',
            height: '25%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminNeedleLoop && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 19)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminNeedleText && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 19)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminPanelTestReport && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 35.5)}
          style={{
            position: 'absolute',
            top: '25%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 32)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminPanelMakeDiagnosis && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 39)}
          style={{
            position: 'absolute',
            top: '47%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminPanelGoBackSelect && (
        <div
          onClick={() => setCurrentVideoTime(FIVE_MINUTES + 3)}
          style={{
            position: 'absolute',
            top: '60%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithDiffVitaminPanelGoBackScreen && (
        <div
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCancer && (
        <div
          id="Cancer_Select_IDA_Diagnosis_CBCWDiff"
          text="Cancer_Select_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 45.5)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '14%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCKD && (
        <div
          id="CKD_Select_IDA_Diagnosis_CBCWDiff"
          text="CKD_Select_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 52)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '33%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisGiDisorders && (
        <div
          id="GI_Disorders_Select_IDA_Diagnosis_CBCWDiff"
          text="GI_Disorders_Select_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '51%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisHF && (
        <div
          id="HF_Select_IDA_Diagnosis_CBCWDiff"
          text="HF_Select_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 7)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '69%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCancerClose && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 41)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCKDClose && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 41)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisGiDisordersClose && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 41)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisHFClose && (
        <div
          onClick={() => setCurrentVideoTime(SEVEN_MINUTES + 41)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCbcWithout && (
        <div
          id="CBC_Without_Differntial_IDA_Diagnosis_CBCWDiff"
          text="CBC_Without_Differntial_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(45.0)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '15%',
            width: '11%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCbcWithoutReflex && (
        <div
          id="CBC_Without_Differential_Reflex_IDA_Diagnosis_CBCWDiff"
          text="CBC_Without_Differential_Reflex_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 10)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '27%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisCbcWithReflex && (
        <div
          id="CBC_With_Differential_Reflex_to_Anemia_IDA_Diagnosis_CBCWDiff"
          text="CBC_With_Differential_Reflex_to_Anemia_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '51%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithIdaDiagnosisIronPanel && (
        <div
          id="Iron_Panel_Select_IDA_Diagnosis_CBCWDiff"
          text="Iron_Panel_Select_IDA_Diagnosis_CBCWDiff"
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '75%',
            width: '11%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexNeedle && (
        <div
          id="Click_Needle_Sample_CBCWODiff_Reflex_Anemia"
          text="Click_Needle_Sample_CBCWODiff_Reflex_Anemia"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 14)}
          style={{
            position: 'absolute',
            top: '56%',
            left: '45%',
            width: '10%',
            height: '24%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexPerformCBCWithReflex && (
        <div
          id="Perform_CBCWODiff_Reflex_Anemia_Panel_Select"
          text="Perform_CBCWODiff_Reflex_Anemia_Panel_Select"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '84%',
            left: '65%',
            width: '26%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexNeedleLoop && (
        <div
          id="Click_Needle_Sample_Graphic_CBCWODiff_Reflex_Anemia"
          text="Click_Needle_Sample_Graphic_CBCWODiff_Reflex_Anemia"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 32)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexNeedleText && (
        <div
          id="Click_Needle_Sample_Text_CBCWODiff_Reflex_Anemia"
          text="Click_Needle_Sample_Text_CBCWODiff_Reflex_Anemia"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 32)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexTestReport && (
        <>
          <div
            id="Review_Test_Report_Anemia_Panel_CBCWODiff"
            text="Review_Test_Report_Anemia_Panel_CBCWODiff"
            onClick={() => {
              setcbcWithoutReflexModalOpen(true);
            }}
            style={{
              position: 'absolute',
              top: '23%',
              left: '28%',
              width: '44%',
              height: '16%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
          <Modal
            isOpen={cbcWithoutReflexmodalOpen}
            className="modal-anemia bigger-modal higher-modal"
            overlayClassName="modal-overlay-anemia"
            shouldFocusAfterRender={false}
            onRequestClose={() => setcbcWithoutReflexModalOpen(false)}
            parentSelector={() => document.getElementById('wirewax-container')}
            ariaHideApp={false}
          >
            <div className="anemia-modal-container">
              <div className="anemia-modal-title">
                SAMPLE TEST REPORT: CBC WITHOUT DIFFERENTIAL, REFLEX TO ANEMIA PANEL
              </div>
              <div className="anemia-modal-inner-container">
                <img src={cbcWithoutReflexImage} alt="" className="modal-image" />
              </div>
              <img
                src={closeButton}
                className="modal-close-x"
                onClick={() => setcbcWithoutReflexModalOpen(false)}
                alt=""
              />
            </div>
          </Modal>
        </>
      )}
      {cbcWithoutReflexMakeDiagnosis && (
        <div
          id="Make_Diganosis_Anemia_Panel_CBCWODiff"
          text="Make_Diganosis_Anemia_Panel_CBCWODiff"
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 57)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '9%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexGoBackScreen && (
        <div
          id="Go_Back_Screen_Another_Patient_Anemia_Panel_CBCWODiff"
          text="Go_Back_Screen_Another_Patient_Anemia_Panel_CBCWODiff"
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '82%',
            left: '28%',
            width: '44%',
            height: '9%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCancer && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 7)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '14%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCKD && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 12)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '33%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisGiDisorders && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 17)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '51%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisHF && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 22)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '69%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCancerClose && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 1)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCKDClose && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 1)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisGiDisordersClose && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 1)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisHFClose && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 1)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCbcWithout && (
        <div
          onClick={() => setCurrentVideoTime(49)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '15%',
            width: '15%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 21%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCbcWith && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '31%',
            width: '15%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisCbcWithReflex && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '47%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithoutReflexIdaDiagnosisIronPanel && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '71%',
            width: '16%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexNeedle && (
        <div
          id="Click_Needle_Sample_CBCWDiff_Reflex_Anemia_Panel"
          text="Click_Needle_Sample_CBCWDiff_Reflex_Anemia_Panel"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 38)}
          style={{
            position: 'absolute',
            top: '61%',
            left: '45%',
            width: '10%',
            height: '26%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexNeedleLoop && (
        <div
          id="Click_Needle_Sample_Graphic_CBCWDiff_Reflex_Anemia_Panel"
          text="Click_Needle_Sample_Graphic_CBCWDiff_Reflex_Anemia_Panel"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexNeedleText && (
        <div
          id="Click_Needle_Sample_Text_CBCWDiff_Reflex_Anemia_Panel"
          text="Click_Needle_Sample_Text_CBCWDiff_Reflex_Anemia_Panel"
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexTestReport && (
        <>
          <div
            id="Review_Test_Report_Anemia_Panel_CBCWDiffer"
            text="Review_Test_Report_Anemia_Panel_CBCWDiffer"
            onClick={() => {
              // setCurrentVideoTime(TEN_MINUTES + 17);
              setcbcWithReflexModalOpen(true);
            }}
            style={{
              position: 'absolute',
              top: '23%',
              left: '28%',
              width: '44%',
              height: '16%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
          <Modal
            isOpen={cbcWithReflexmodalOpen}
            className="modal-anemia bigger-modal higher-modal"
            overlayClassName="modal-overlay-anemia"
            shouldFocusAfterRender={false}
            onRequestClose={() => setcbcWithReflexModalOpen(false)}
            parentSelector={() => document.getElementById('wirewax-container')}
            ariaHideApp={false}
          >
            <div className="anemia-modal-container">
              <div className="anemia-modal-title">
                SAMPLE TEST REPORT: CBC WITH DIFFERENTIAL, REFLEX TO ANEMIA PANEL
              </div>
              <div className="anemia-modal-inner-container">
                <img src={cbcWithReflexImage} alt="" className="modal-image" />
              </div>
              <img
                src={closeButton}
                className="modal-close-x"
                onClick={() => setcbcWithReflexModalOpen(false)}
                alt=""
              />
            </div>
          </Modal>
        </>
      )}
      {cbcWithReflexMakeDiagnosis && (
        <div
          id="Make_Diganosis_Anemia_Panel_CBCWDiffer"
          text="Make_Diganosis_Anemia_Panel_CBCWDiffer"
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 23)}
          style={{
            position: 'absolute',
            top: '72%',
            left: '28%',
            width: '44%',
            height: '9%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexGoBackScreen && (
        <div
          id="Go_Back_Screen_Another_Patient_Anemia_Panel_CBCWDiffer"
          text="Go_Back_Screen_Another_Patient_Anemia_Panel_CBCWDiffer"
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '82%',
            left: '28%',
            width: '44%',
            height: '9%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCancer && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 33)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '14%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCKD && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 39)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '33%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisGiDisorders && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 43)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '51%',
            width: '17%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisHF && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 48)}
          style={{
            position: 'absolute',
            top: '51%',
            left: '69%',
            width: '18%',
            height: '6%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCancerClose && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 26)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCKDClose && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 26)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisGiDisordersClose && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 26)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisHFClose && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 26)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCbcWithout && (
        <div
          onClick={() => setCurrentVideoTime(49)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '15%',
            width: '15%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 21%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCbcWith && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '31%',
            width: '15%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisCbcWithoutReflex && (
        <div
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 9.5)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '47%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {cbcWithReflexIdaDiagnosisIronPanel && (
        <div
          onClick={() => setCurrentVideoTime(TEN_MINUTES + 55)}
          style={{
            position: 'absolute',
            top: '68%',
            left: '71%',
            width: '16%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelNeedle && (
        <div
          onClick={() => setCurrentVideoTime(ELEVEN_MINUTES + 2)}
          style={{
            position: 'absolute',
            top: '64%',
            left: '45%',
            width: '10%',
            height: '26%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelNeedleLoop && (
        <div
          onClick={() => setCurrentVideoTime(ELEVEN_MINUTES + 19)}
          style={{
            position: 'absolute',
            top: '30%',
            left: '57%',
            width: '23%',
            height: '40%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelNeedleText && (
        <div
          onClick={() => setCurrentVideoTime(ELEVEN_MINUTES + 19)}
          style={{
            position: 'absolute',
            top: '46%',
            left: '8%',
            width: '24%',
            height: '8%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelTestReport && (
        <div
          onClick={() => setCurrentVideoTime(ELEVEN_MINUTES + 37)}
          style={{
            position: 'absolute',
            top: '32%',
            left: '28%',
            width: '44%',
            height: '16%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelTestReportClose && (
        <div
          onClick={() => setCurrentVideoTime(ELEVEN_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelMakeDiagnosis && (
        <div
          onClick={() => setCurrentVideoTime(ELEVEN_MINUTES + 43)}
          style={{
            position: 'absolute',
            top: '53%',
            left: '28%',
            width: '44%',
            height: '11%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelGoBackScreen && (
        <div
          onClick={() => setCurrentVideoTime(42.0)}
          style={{
            position: 'absolute',
            top: '66%',
            left: '28%',
            width: '44%',
            height: '10%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelIdaDiagnosisCbcWithout && (
        <div
          onClick={() => setCurrentVideoTime(49)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '14%',
            width: '11%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 21%)'
          }}
        />
      )}
      {ironPanelIdaDiagnosisCbcWith && (
        <div
          onClick={() => setCurrentVideoTime(FOUR_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '27%',
            width: '10%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelIdaDiagnosisCbcWithoutReflex && (
        <div
          onClick={() => setCurrentVideoTime(EIGHT_MINUTES + 9.5)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '39%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {ironPanelIdaDiagnosisWithReflex && (
        <div
          onClick={() => setCurrentVideoTime(NINE_MINUTES + 30)}
          style={{
            position: 'absolute',
            top: '59%',
            left: '63%',
            width: '23%',
            height: '22%',
            cursor: 'pointer',
            zIndex: 2
            //backgroundColor: 'rgb(290 255 0 / 71%)'
          }}
        />
      )}
      {hemoglobinConcHome && (
        <Tippy content={glossaryConfig['Hb concentration']} arrow={true} theme="custom-grey-black">
          <div
            style={{
              position: 'absolute',
              top: '42%',
              left: '55.5%',
              width: '20%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {hematocritHome && (
        <Tippy
          content={glossaryConfig['Hematocrit']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '42%',
              left: '76%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {rbcCountHome && (
        <Tippy
          content={glossaryConfig['RBC count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '42%',
              left: '85%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {plateletCountCbcWithout && (
        <Tippy
          content={glossaryConfig['Platelet count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '59.5%',
              width: '9%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {wbccountCbcbWithout && (
        <Tippy
          content={glossaryConfig['WBC count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '71%',
              width: '20%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {ferritinCbcWithoutIron && (
        <Tippy
          content={glossaryConfig['Ferritin']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '25%',
              width: '5%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tibcCbcWithoutIron && (
        <Tippy
          content={glossaryConfig['TIBC']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '47%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {serumIronCbcWithoutIron && (
        <Tippy
          content={glossaryConfig['Serum Iron']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '52%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tsatCbcWithoutIron && (
        <Tippy
          content={glossaryConfig['TSAT']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '76%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {functionalIronCbcWithoutIron && (
        <Tippy
          content={glossaryConfig['Functional Iron Deficiency']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '31%',
              left: '25.5%',
              width: '17%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {vitaminb12CbcWithoutAnemia && (
        <Tippy
          content={glossaryConfig['Vitamin B12']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '27%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {folateCbcWithoutAnemia && (
        <Tippy
          content={glossaryConfig['Folate']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '38%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {retiCountCbcWithoutAnemia && (
        <Tippy
          content={glossaryConfig['Reticulocyte count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '49.5%',
              width: '12%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {plateletCountCbcWith && (
        <Tippy
          content={glossaryConfig['Platelet count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '59%',
              width: '9%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {wbcCountCbcWith && (
        <Tippy
          content={glossaryConfig['WBC count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '72%',
              width: '18%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {neutrophilsCbcWith && (
        <Tippy
          content={glossaryConfig['Neutrophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '28%',
              left: '70%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {lymphocytesCbcWith && (
        <Tippy
          content={glossaryConfig['Lymphocytes']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '28%',
              left: '78.5%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {monocytesCbcWith && (
        <Tippy
          content={glossaryConfig['Monocytes']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '31%',
              left: '10%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {eosinophilsCbcWith && (
        <Tippy
          content={glossaryConfig['Eosinophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '31%',
              left: '17.5%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {basophilsCbcWith && (
        <Tippy
          content={glossaryConfig['Basophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '31%',
              left: '28%',
              width: '6%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {ferritinCbcWithIron && (
        <Tippy
          content={glossaryConfig['Ferritin']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '25%',
              width: '5%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tibcCbcWithIron && (
        <Tippy
          content={glossaryConfig['TIBC']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '47%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {serumIronCbcWithIron && (
        <Tippy
          content={glossaryConfig['Serum Iron']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '52%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tsatCbcWithIron && (
        <Tippy
          content={glossaryConfig['TSAT']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '24.5%',
              left: '76%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {functionalIronCbcWithIron && (
        <Tippy
          content={glossaryConfig['Functional Iron Deficiency']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '31%',
              left: '26%',
              width: '16%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithAnemia && (
        <Tippy
          content={glossaryConfig['Vitamin B12']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '27%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithAnemia && (
        <Tippy
          content={glossaryConfig['Folate']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '38%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithAnemia && (
        <Tippy
          content={glossaryConfig['Reticulocyte count']}
          arrow={true}
          theme="custom-grey-black"
          placement="top"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '49.5%',
              width: '12%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['Platelet count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '21%',
              left: '59.5%',
              width: '9%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['WBC count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '21%',
              left: '72%',
              width: '19%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['Neutrophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '24%',
              left: '70%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['Lymphocytes']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-end"
        >
          <div
            style={{
              position: 'absolute',
              top: '24%',
              left: '78.5%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['Monocytes']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '27%',
              left: '10%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['Eosinophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '27%',
              left: '17.5%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflex && (
        <Tippy
          content={glossaryConfig['Basophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '27%',
              left: '28%',
              width: '6%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['Platelet count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '59%',
              width: '10%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['WBC count']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: '72%',
              width: '19%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['Neutrophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '28%',
              left: '70%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['Lymphocytes']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '28%',
              left: '78%',
              width: '8%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['Monocytes']}
          arrow={true}
          theme="custom-grey-black"
          placement="bottom"
        >
          <div
            style={{
              position: 'absolute',
              top: '31.5%',
              left: '10%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['Eosinophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '31.5%',
              left: '17.5%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflex && (
        <Tippy
          content={glossaryConfig['Basophils']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '31.5%',
              left: '28%',
              width: '6%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflexPanel && (
        <Tippy
          content={glossaryConfig['Macrocytic anemia']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '59%',
              left: '44%',
              width: '13%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithReflexPanel && (
        <Tippy
          content={glossaryConfig['Microcytic anemia']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '62%',
              left: '58.5%',
              width: '12%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyIronPanel && (
        <Tippy
          content={glossaryConfig['Ferritin']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '33%',
              left: '24%',
              width: '5%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyIronPanel && (
        <Tippy
          content={glossaryConfig['TIBC']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '33%',
              left: '46%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyIronPanel && (
        <Tippy
          content={glossaryConfig['Serum Iron']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '33%',
              left: '51%',
              width: '7%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyIronPanel && (
        <Tippy
          content={glossaryConfig['TSAT']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '33%',
              left: '75%',
              width: '4%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyIronPanel && (
        <Tippy
          content={glossaryConfig['Functional Iron Deficiency']}
          arrow={true}
          theme="custom-grey-black"
          placement="top-start"
        >
          <div
            style={{
              position: 'absolute',
              top: '40%',
              left: '41%',
              width: '17%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              //backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflexPanel && (
        <Tippy
          content={glossaryConfig['Macrocytic anemia']}
          arrow={true}
          theme="custom-grey-black"
          placement="top"
        >
          <div
            style={{
              position: 'absolute',
              top: '59%',
              left: '45%',
              width: '12%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              // backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
      {tippyCbcWithoutReflexPanel && (
        <Tippy
          content={glossaryConfig['Microcytic anemia']}
          arrow={true}
          theme="custom-grey-black"
          placement="top"
        >
          <div
            style={{
              position: 'absolute',
              top: '62%',
              left: '59%',
              width: '12%',
              height: '3%',
              cursor: 'pointer',
              zIndex: 2
              // backgroundColor: 'rgb(290 255 0 / 71%)'
            }}
          />
        </Tippy>
      )}
    </>
  );
};

export default VideoHotSpots;

import '../../App.css';

const TrainingWarning = ({ isTrainingLabelOpen, PieChart }) => {
  return (
    <div
      className="training-container"
      style={{
        display: isTrainingLabelOpen ? 'flex' : 'none',
        justifyContent: PieChart && 'space-between'
      }}
    >
      {PieChart && (
        <div style={{ width: '57%', textAlign: 'justify' }}>
          HER3, human epidermal growth factor receptor 3; <i>MET</i>ex14, <i>MET</i> with exon 14
          skipping mutation;
          <br /> TROP-2, trophoblast cell surface antigen 2.
        </div>
      )}
      <span>
        FOR INTERNAL TRAINING PURPOSES ONLY. DO NOT DETAIL OR DISTRIBUTE. &nbsp;&nbsp; PP-US-NP-2633
        11/24
      </span>
    </div>
  );
};

export default TrainingWarning;

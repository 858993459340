import React from 'react';
import LandingButtons from '../ihc/LandingButtons';
import Laboratory from '../common/Laboratory';

const IHCLaboratory = ({ videoEl }) => (
  <Laboratory
    content={
      'Examples of IHC LDTs include tests developed using the polyclonal rabbit anti&#x2011;human c-erbB-2 oncoprotein, code A0485 (Dako/Agilent Technologies).<sup>&#10013;</sup>'
    }
    buttons={<LandingButtons videoEl={videoEl} />}
    footer={
      '<div style="text-align: left;">*Tests should be performed in laboratories that adhere to Clinical Laboratory Improvement Amendment of 1988 (CLIA) and CAP accreditation requirements.<br /><sup>&#10013;</sup>This antibody is also used by HercepTest. Unlike LDTs, HercepTest is an FDA-approved companion diagnostic.</div>'
    }
    footer2={
      'FOR INTERNAL TRAINING PURPOSES ONLY. DO NOT DETAIL OR DISTRIBUTE. &nbsp;&nbsp; PP-US-NP-2633 11/24'
    }
  />
);

export default IHCLaboratory;

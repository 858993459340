import React from 'react';
import { useParams, Link } from 'react-router-dom';
import GroupsConfig from '../config/groups';
import '../styles/landing.css';

const Landing = ({ videoEl }) => {
  const { group, assay } = useParams();
  const { laboratory, topContent, exploreContentHeader, bottomContent, includeAuthor } =
    GroupsConfig[group].assays[assay].landing;
  const footer = `FOR INTERNAL TRAINING PURPOSES ONLY. DO NOT DETAIL OR DISTRIBUTE. &nbsp;&nbsp; PP-US-NP-2633 11/24`;
  if (laboratory !== undefined) {
    const ClonedLaboratory = React.cloneElement(laboratory, { videoEl });
    return ClonedLaboratory;
  }

  const { slides, layout } = GroupsConfig[group].assays[assay].wall;

  const clonedBottomContent = React.cloneElement(bottomContent, { videoEl });
  return (
    <div className="landing-container app">
      {topContent}
      <div className="slides-buttons-container">
        <div className="slides-container">
          {topContent.exploreContent && (
            <div className="header">
              <span className="green">EXPLORE</span>{' '}
              {layout === 'dna' ? 'POTENTIAL RESULTS' : 'TEST RESULTS'}
            </div>
          )}
          {exploreContentHeader && !topContent.exploreContent && (
            <div className="header">
              <span className="green">EXPLORE</span>{' '}
              {layout === 'dna' ? 'POTENTIAL RESULTS' : 'TEST RESULTS'}
            </div>
          )}
          {layout === 'dna' && (
            <div className="groups-container">
              {slides.map((slide, i) => (
                <Link key={i} className="group" to={`/wall/${group}/${assay}/${i}`}>
                  <div className="name">{slide.name}</div>
                  <img src={slide.thumb} alt="" className={slide.customWidthClass} />
                </Link>
              ))}
            </div>
          )}
        </div>
        {clonedBottomContent}

        <div style={{ display: 'flex', justifyContent: includeAuthor ? 'space-between' : 'flex-end', flex: 1, width: '100%' }}>
          {includeAuthor && (
            <div className="landing-footer">Adapted from Yong L et al.  <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.</div>
          )}
          <div className="landing-footer" dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
      </div>
    </div>
  );
};

export default Landing;
